'use strict';

import React, {useState, useEffect} from 'react';
import {View, Text, StyleSheet, Image} from 'react-native';

import {colors} from '../classes/Colors';
import DataController from '../classes/DataController';
import {getIconSource} from '../utils/icons';
import CustomButton from './CustomButton';
import {
  getStringsForPromoCard,
  getGreggsPromotionDateStr,
} from '../constants/greggsPromotion';

// type Thumbnail = {
//   url: string,
//   width: number,
//   height: number,
// };

// type Image = {
//   id: string,
//   width: number,
//   height: number,
//   url: string,
//   filename: string,
//   size: number,
//   type: string,
//   thumbnails: {
//     small: Thumbnail,
//     large: Thumbnail,
//     full: Thumbnail,
//   },
// };

// type FeaturedCard = {
//   id: string,
//   Trusts: string[],
//   Image: Image[],
//   SortOrder: number,
//   Quiz: string,
//   Subtitle: string,
//   Live: boolean,
//   Caption: string,
//   Title: string,
//   Icon: string,
//   LastModified: string,
//   onPress: () => void,
//   promoData
// };

const FeaturedCard = ({
  featuredCard,
  onPress,
  isButtonDisabled,
  promoData,
  promoFinalDate,
}) => {
  const [imageURL, setImageURL] = useState('');

  const isPromo = featuredCard?.Icon === 'Promotion';
  const hasPromoCode = promoData?.promoCode;
  const showPromoCode = isPromo && hasPromoCode;
  const hasCollected = showPromoCode && isButtonDisabled;

  useEffect(() => {
    const fetchImageURL = async () => {
      if (!featuredCard?.Image) {
        return;
      }
      const imageURL = await DataController.getFeaturedCardImagePath(
        featuredCard,
        featuredCard.Image[0].filename,
      );
      setImageURL(imageURL);
    };

    fetchImageURL();
  }, [featuredCard?.Image?.[0]?.filename]);

  const iconSource = getIconSource(featuredCard?.Icon);

  const imageLayout = imageURL ? (
    <Image style={styles.image} resizeMode="cover" source={{uri: imageURL}} />
  ) : (
    <Image
      style={styles.placeholderImage}
      resizeMode="contain"
      source={require('../assets/Image_Loading_Placeholder.png')}
    />
  );

  const button = isButtonDisabled ? (
    <CustomButton
      title="Collected"
      buttonStyle={styles.collectedButton}
      titleStyle={styles.collectedButtonTitle}
      disabled={true}
    />
  ) : (
    <CustomButton
      title={promoData?.promoCode}
      buttonStyle={styles.promoButton}
      titleStyle={styles.promoButtonTitle}
      iconComponent={
        <Image
          style={styles.icon}
          source={require('../assets/promo/qr_code_blue.png')}
        />
      }
      onPress={onPress}
    />
  );

  const {title: promoTitle} = getStringsForPromoCard(promoData, hasCollected);
  const title = isPromo
    ? promoTitle.replace('Congratulations!\n', '')
    : featuredCard.Title;

  return (
    <View style={styles.container}>
      {imageLayout}
      <View style={styles.content}>
        {(iconSource || featuredCard.Caption) && (
          <View style={styles.iconContainer}>
            {iconSource && <Image style={styles.icon} source={iconSource} />}
            <Text style={isPromo ? styles.promoCaption : styles.caption}>
              {featuredCard.Caption.toUpperCase()}
              {isPromo && `${getGreggsPromotionDateStr(promoFinalDate)}`}
            </Text>
          </View>
        )}
        <Text style={styles.title}>{title}</Text>
        {showPromoCode ? (
          button
        ) : (
          <Text style={styles.subtitle}>{featuredCard.Subtitle}</Text>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    shadowOffset: {width: 0, height: 1},
    shadowRadius: 1,
    shadowColor: 'black',
    shadowOpacity: 0.2,
    backgroundColor: colors.white,
  },
  image: {
    height: 180,
  },
  placeholderImage: {
    width: '100%',
    height: 180,
    backgroundColor: '#EFF4F6',
  },
  content: {
    justifyContent: 'flex-start',
    padding: 10,
    paddingHorizontal: 12,
  },
  iconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
  },
  icon: {
    marginRight: 6,
    width: 24,
    height: 24,
  },
  caption: {
    fontSize: 11,
    fontWeight: '700',
    color: colors.Grey800,
  },
  promoCaption: {
    fontSize: 11,
    fontWeight: '700',
    color: colors.PromoBlue,
  },
  title: {
    marginBottom: 8,
    fontSize: 18,
    fontWeight: '700',
    color: colors.darkGrey,
  },
  subtitle: {
    marginBottom: 16,
    fontSize: 13,
    fontWeight: '600',
    color: colors.Grey800,
  },
  promoButton: {
    borderColor: colors.PromoBlueDarkest,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderRadius: 6,
    padding: 14,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    alignSelf: 'flex-end',
  },
  promoButtonTitle: {
    color: colors.PromoBlueDarkest,
    fontWeight: '700',
    fontSize: 17,
  },
  collectedButton: {
    backgroundColor: colors.Grey200,
    borderRadius: 6,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 14,
    width: '100%',
    alignSelf: 'flex-end',
  },
  collectedButtonTitle: {
    color: colors.PromoGrey,
    fontWeight: '700',
    fontSize: 17,
  },
  dateText: {
    marginTop: 8,
    color: colors.Grey800,
    fontSize: 11,
    fontWeight: '400',
  },
});

export default FeaturedCard;
