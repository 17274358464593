/**
 * PPP
 * @flow
 */
'use strict';
import React, {Component} from 'react';
import {StyleSheet, Text, View, ScrollView} from 'react-native';
import ScreenComponent from '../components/ScreenComponent';
import {colors} from '../classes/Colors';
import LayoutController from '../classes/LayoutController';
import VimeoPlayer from '../components/VimeoPlayer';
import moment from 'moment';
import DataController from '../classes/DataController';
import HTMLRenderer from '../components/HTMLRenderer';

export default class VideoScreen extends Component {
  static navigationOptions = ({navigation}) => {
    return {
      title: navigation.getParam('title', ''),
      headerStyle: {
        backgroundColor: colors.NavBar,
      },
      headerTintColor: colors.white,
      headerTitleStyle: {
        fontFamily: 'InterUI-Regular',
        fontSize: 17,
        fontWeight: '600',
        letterSpacing: -0.41,
        color: colors.white,
      },
    };
  };

  constructor(props: Props) {
    super(props);
    this.player = null;
  }

  keyExtractor(item, index) {
    return item.name;
  }

  render() {
    const video = this.props.navigation.getParam('video', null);
    const allVideoHistory = this.props.navigation.getParam('videoHistory', []);
    const currentVideoHistory = allVideoHistory.find(
      vid => vid.videoId === video.id,
    );

    return (
      <ScreenComponent style={[styles.container]}>
        <ScrollView
          styles={{flex: 1}}
          contentContainerStyle={{backgroundColor: colors.white}}>
          <View
            style={{
              alignItems: LayoutController.isCompact() ? 'stretch' : 'center',
            }}>
            <View
              style={{
                maxWidth: 700,
                alignItems: 'stretch',
              }}>
              <VimeoPlayer
                video={video}
                updateVideoHistory={this.props.screenProps.updateVideoHistory}
              />
              <View
                style={{
                  paddingHorizontal: LayoutController.isCompact() ? 12 : 0,
                }}>
                <View style={styles.vidInfoContainer}>
                  <Text
                    style={{
                      color: colors.Grey700,
                      fontWeight: '500',
                      fontSize: 12,
                    }}>
                    {moment(video.ReleaseDate).format('DD MMM YYYY')}
                  </Text>
                  <View
                    style={{
                      backgroundColor: 'rgb(121, 139, 147)',
                      borderRadius: 1,
                      height: 2,
                      marginRight: 6,
                      marginLeft: 6,
                      width: 2,
                    }}
                  />
                  <View
                    style={{
                      justifyContent: 'center',
                      paddingHorizontal: 6,
                      height: 16,
                      borderRadius: 2,
                      backgroundColor: DataController.getColorForTrust(
                        video.Trust,
                      ),
                    }}>
                    <Text
                      style={{
                        fontSize: 12,
                        fontWeight: '500',
                        letterSpacing: -0.1,
                        color: colors.white,
                      }}>
                      {DataController.getTrustDisplayName(video.Trust)}
                    </Text>
                  </View>
                  {currentVideoHistory &&
                    currentVideoHistory.secondsWatched >= 30 && (
                      <>
                        <View
                          style={{
                            backgroundColor: 'rgb(121, 139, 147)',
                            borderRadius: 1,
                            height: 2,
                            marginRight: 6,
                            marginLeft: 6,
                            width: 2,
                          }}
                        />
                        <Text
                          style={{color: colors.Grey700, fontWeight: '500'}}>
                          Seen
                        </Text>
                      </>
                    )}
                </View>
                <Text style={styles.title}>{video.Title}</Text>
                <HTMLRenderer html={video.Description} />
              </View>
            </View>
          </View>
        </ScrollView>
      </ScreenComponent>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  vidInfoContainer: {
    marginTop: 12,
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    marginTop: 12,
    fontSize: 22,
    fontWeight: '600',
    color: colors.Grey900,
  },
});
