/**
 * PPP
 * @flow
 */
'use strict';
import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Platform,
  TouchableHighlight,
  TouchableOpacity,
  ScrollView,
  Image,
  Alert,
} from 'react-native';

import AMWebView from '../components/AMWebView';

import ScreenComponent from '../components/ScreenComponent';
import DataController from '../classes/DataController';
import LayoutController from '../classes/LayoutController';

import {colors} from '../classes/Colors';
import RemoteDataController from '../classes/RemoteDataController';

var _ = require('lodash');
var moment = require('moment');

type Props = {};

type State = {
  guidelineID: string,
  guideline: Object,
  html: string,
};

export default class ReferenceScreen extends Component {
  static navigationOptions = ({navigation}) => {
    return {
      title: 'JRCALC Reference',
      headerStyle: {
        backgroundColor: colors.NavBar,
      },
      headerTintColor: colors.white,
      headerTitleStyle: {
        fontFamily: 'InterUI-Regular',
        fontSize: 17,
        fontWeight: '600',
        letterSpacing: -0.41,
        color: colors.white,
      },
    };
  };

  constructor(props: Props) {
    super(props);

    if (_.isEmpty(this.props.initialState)) {
      this.state = {
        caseStudy: this.props.navigation.getParam('caseStudy', {}),
        guidelineID: '',
        guideline: null,
        html: '',
        guidelineContents: this.props.navigation.getParam(
          'guidelineContents',
          '',
        ),
        question: this.props.navigation.getParam('question', {}),
        reference: this.props.navigation.getParam('reference', ''),
        section: this.props.navigation.getParam('section', {}),
      };
    } else {
      this.state = this.props.initialState;
    }
  }

  async componentDidMount() {
    if (_.isEmpty(this.props.initialState)) {
      // console.log('Did mount');
      var guidelineContents = this.state.guidelineContents;

      this.setState({
        guidelineID: guidelineContents['cpg-guidelineid'],
        guideline: guidelineContents,
      });

      var fontSize = await LayoutController.getFontSize();

      var paddingString = 'padding: 10px;';

      if (Platform.OS === 'ios' || Platform.OS === 'android') {
        paddingString = '';
      }

      var html =
        '<!DOCTYPE html><html><head><meta name="viewport" content="width=device-width" initial-scale=1><link rel="stylesheet" type="text/css" href="style.css"><link rel="stylesheet" type="text/css" href="style2.css"><link rel="stylesheet" type="text/css" href="Feed2.css"><link rel="stylesheet" type="text/css" href="SharedCss.css"><link rel="stylesheet" type="text/css" href="CM.css"><style>body {font-size: ' +
        fontSize +
        'px;} .content{overflow-x: scroll;} img {max-width: 100%;}</style><script src="textHighlighting.js"></script> <script src="jquery-3.5.1.min.js"></script></head><body><div style="' +
        paddingString +
        '">';

      html =
        html +
        "<div class='ACCEGuidelineTitle'>" +
        guidelineContents.title +
        '</div>';

      var startTextHighlight = this.state.question.StartTextHighlight;
      var endTextHighlight = this.state.question.EndTextHighlight;

      // startTextHighlight = 'Where';;
      // endTextHighlight = 'defibrillator';

      if (guidelineContents.title.includes('Page for Age')) {
        var topTablesHTML = '';

        if (
          guidelineContents.vitalSigns !== null &&
          guidelineContents.vitalSigns !== undefined
        ) {
          var vitalSignsHTML = guidelineContents.vitalSigns;

          topTablesHTML = topTablesHTML + vitalSignsHTML;
        }

        if (
          guidelineContents.TableAndBoxes1 !== null &&
          guidelineContents.TableAndBoxes1 !== undefined
        ) {
          var tableSection = guidelineContents.TableAndBoxes1[0];

          var tableHtml = this.getHtmlForTable(
            tableSection,
            this.state.guidelineID,
          );

          console.log('html table');
          console.log(tableSection);

          topTablesHTML = topTablesHTML + tableHtml;
        }

        if (
          guidelineContents.TableAndBoxes2 !== null &&
          guidelineContents.TableAndBoxes2 !== undefined
        ) {
          var tableSection = guidelineContents.TableAndBoxes2[0];

          var tableHtml = this.getHtmlForTable(
            tableSection,
            this.state.guidelineID,
          );

          console.log('html table');
          console.log(tableSection);

          topTablesHTML = topTablesHTML + tableHtml;
        }

        if (this.state.reference.includes('All Sections'))
          if (topTablesHTML.length > 0) {
            topTablesHTML = this.addHighlightToHTML(
              topTablesHTML,
              startTextHighlight,
              endTextHighlight,
            );
          }

        html = html + topTablesHTML;
      }

      // add quicklook section to top
      var updatedSections = [];
      for (
        let sectionIndex = 0;
        sectionIndex < guidelineContents.sections.length;
        sectionIndex++
      ) {
        var section = guidelineContents.sections[sectionIndex];

        if (
          section.SectionTitle === 'KEY POINTS' ||
          section.SectionTitle === 'Key points'
        ) {
          var newSection = {
            QuickLook: true,
            sectionHTML: section.sectionHTML,
            SectionID: section.SectionID,
            SectionTitle: 'Quick Look',
          };

          updatedSections.unshift(newSection);
        }

        updatedSections.push(section);
      }

      guidelineContents.sections = updatedSections;

      // create html for each section
      for (
        let sectionIndex = 0;
        sectionIndex < guidelineContents.sections.length;
        sectionIndex++
      ) {
        var section = guidelineContents.sections[sectionIndex];

        var sectionHTML = section.sectionHTML;

        if (sectionHTML.includes('<title2') === false) {
          sectionHTML =
            '<title2>' + section.SectionTitle + '</title2>' + sectionHTML;
        }

        if (section.SectionTitle == this.state.section) {
          // console.log(section);
          html = html + "<span id='foo'></span>";

          sectionHTML = "<div id='selectedSection'>" + sectionHTML + '</div>';
        }

        // get tables referenced in section and insert them into html
        var tableRegEx =
          /<AACETableInsertPosition [^>]*>(.*?)<\/AACETableInsertPosition>/g;

        var matches = sectionHTML.match(tableRegEx);

        if (matches !== null) {
          for (var matchIndex = 0; matchIndex < matches.length; matchIndex++) {
            var match = matches[matchIndex];

            var idValue = match.substr(match.indexOf('id=') + 3);
            idValue = idValue.substr(0, idValue.indexOf(' '));

            var tableHTML = this.getAACETable(idValue);

            sectionHTML = sectionHTML.replace(match, tableHTML);
          }
        }

        // get tables referenced in section and insert them into html
        var figureRegEx =
          /<AACEFigureInsertPosition [^>]*>(.*?)<\/AACEFigureInsertPosition>/g;

        var figureMatches = sectionHTML.match(figureRegEx);

        if (figureMatches !== null) {
          for (
            var matchIndex = 0;
            matchIndex < figureMatches.length;
            matchIndex++
          ) {
            var match = figureMatches[matchIndex];

            var idValue = match.substr(match.indexOf('id=') + 3);
            idValue = idValue.substr(0, idValue.indexOf(' '));

            var figureHTML = this.getAACEFigure(idValue, guidelineContents);

            sectionHTML = sectionHTML.replace(match, figureHTML);
          }
        }

        var indexEntryRegEx = /<index-entry1 [^>]*>(.*?)<\/index-entry1>/g;

        var indexEntryMatches = sectionHTML.match(indexEntryRegEx);

        if (indexEntryMatches !== null) {
          for (
            var indexEntryIndex = 0;
            indexEntryIndex < indexEntryMatches.length;
            indexEntryIndex++
          ) {
            var match = indexEntryMatches[indexEntryIndex];

            sectionHTML = sectionHTML.replace(match, '');
          }
        }

        console.log('logging section');
        console.log(this.state.section);
        console.log(section.SectionTitle);

        if (section.SectionTitle == this.state.section) {
          console.log('found section');

          sectionHTML = this.addHighlightToHTML(
            sectionHTML,
            startTextHighlight,
            endTextHighlight,
          );
        }

        html = html + sectionHTML;
      }

      // replace img tags
      var imageRegEx = /<img [^>]*\/>/g;

      var imageMatches = html.match(imageRegEx);

      console.log('image matches');
      console.log(imageMatches);

      if (imageMatches !== null) {
        for (
          let matchIndex = 0;
          matchIndex < imageMatches.length;
          matchIndex++
        ) {
          var match = imageMatches[matchIndex];
          console.log(match);

          // if (match.includes('src="G0')) {

          if (Platform.OS === 'ios' || Platform.OS === 'android') {
            // get image urls as base64 - as can't load images regularly on iOS for some reason
            var imageFileName = match
              .substring(match.indexOf('src="') + 5, match.indexOf('/>'))
              .replace('"', '');

            var imagePath = await DataController.getGuidelineImagePath(
              imageFileName,
            );

            var base64String =
              await DataController.getBase64ImageStringForImagePath(imagePath);

            var imageType = 'jpeg';

            if (imageFileName.includes('png')) {
              imageType = 'png';
            }

            html = html.replace(
              match,
              '<img alt="" id="' +
                imagePath +
                '" src="data:image/' +
                imageType +
                ';base64, ' +
                base64String +
                '"/>',
            );

            var test = '';
          } else {
            var imageFileName = match
              .substring(match.indexOf('src="') + 5, match.indexOf('/>'))
              .replace('"', '');

            var imagePath = await DataController.getGuidelineImagePath(
              imageFileName,
            );

            let response = await RemoteDataController.customFetch(imagePath);
            if (response != null && response.status === 200) {
              html = html.replace(
                match,
                '<img alt="" id="' + imagePath + '" src="' + imagePath + '"/>',
              );
            } else {
              imagePath =
                'https://jrcalc.s3.amazonaws.com/Feed-Sup/GLimage/' +
                imageFileName;
              html = html.replace(
                match,
                '<img alt="" id="' + imagePath + '" src="' + imagePath + '"/>',
              );
            }
          }
          // }
        }
      }

      // strip object tags
      var objectRegEx = /<object [^>]*\/>/g;

      var objectMatches = html.match(objectRegEx);

      if (objectMatches !== null) {
        for (
          var matchIndex = 0;
          matchIndex < objectMatches.length;
          matchIndex++
        ) {
          var match = objectMatches[matchIndex];

          html = html.replace(match, '');
        }
      }

      console.log(html);

      var scrollID = 'selectedSection';

      if (html.includes('appHighlight')) {
        scrollID = 'appHighlight';
      }

      var scrollScript = '';

      if (Platform.OS === 'web') {
        // scrollScript = `<script>
        //
        // setTimeout(function() {
        //   document.getElementById('` + scrollID + `').scrollIntoView();
        //   window.scrollBy(0, -100);
        //   console.log('scrolling content');
        // }, 100);
        //
        // </script>`;
      } else {
        scrollScript =
          `<script>

        setTimeout(function() {
          var divPosition = $('#` +
          scrollID +
          `').offset();
          $('html, body').scrollTop(divPosition.top - 100);
        }, 100);

      </script>`;
      }

      var disableLinksScript = `
      <script>
        $('a.externalLink').click(function() { return false; });
      </script>
    `;

      // if (startTextHighlight != null && endTextHighlight != null) {
      //   scrollScript = "<script>selectAndHighlightRange('test', \"" + startTextHighlight + "\", \"" + endTextHighlight + "\")</script>";
      // }

      html = html + '<p></p>';

      var referenceParts = this.state.reference.split(' - ');
      var glid = referenceParts[0];

      var endReference = '<div>' + glid + '</div>';

      // html = `<!DOCTYPE html><html><head><link rel="stylesheet" type="text/css" href="style.css"><link rel="stylesheet" type="text/css" href="style2.css"><link rel="stylesheet" type="text/css" href="Feed2.css"><link rel="stylesheet" type="text/css" href="SharedCss.css"><link rel="stylesheet" type="text/css" href="CM.css"><style>body {font-size: ' + fontSize  + 'px;}</style><script src="textHighlighting.js"></script> <script src="jquery-3.5.1.min.js"></script></head><body><div style="padding: 10px;">`;
      // html += 'Hello';

      html =
        html +
        scrollScript +
        disableLinksScript +
        endReference +
        '</div></body></html>';

      var that = this;

      this.setState({html: html}, function () {
        if (Platform.OS === 'web') {
          var that = this;

          setTimeout(function () {
            that.scrollWebPage();
          }, 500);

          setTimeout(function () {
            that.scrollWebPage();
          }, 1000);
        }
      });
    }
  }

  scrollWebPage() {
    var scrollID = 'selectedSection';

    if (this.state.html.includes('appHighlight')) {
      scrollID = 'appHighlight';
    }

    if (document.getElementById(scrollID) != null) {
      document.getElementById(scrollID).scrollIntoView();
    }

    setTimeout(function () {
      window.scrollBy(0, -200);
    }, 500);
  }

  addHighlightToHTML(
    sectionToSearch: string,
    startTextHighlight: string,
    endTextHighlight: string,
  ): string {
    var sectionHTML = sectionToSearch;

    var startIndices = this.getIndicesOf(
      startTextHighlight,
      sectionHTML,
      false,
    );
    var selectedStartPosition = -1;
    var startPosition = 0;
    var endPosition = 0;

    console.log(startTextHighlight);
    console.log(sectionHTML);
    console.log(startIndices);

    if (startIndices.length > 0) {
      startPosition = startIndices[0];

      selectedStartPosition = startPosition;

      var highlightSpan = "<div id='appHighlight'>";

      sectionHTML =
        sectionHTML.substr(0, startPosition) +
        highlightSpan +
        sectionHTML.substr(startPosition);

      console.log('html: ' + sectionHTML);
    }

    console.log('start position = ' + selectedStartPosition);

    if (selectedStartPosition >= 0) {
      console.log('start indices = ' + startIndices);

      var endIndices = this.getIndicesOf(endTextHighlight, sectionHTML, false);

      console.log('end indices = ' + endIndices);

      if (endIndices.length > 0) {
        var selectedEndPosition = -1;

        for (var endIndex of endIndices) {
          if (endIndex > selectedStartPosition) {
            selectedEndPosition = endIndex;
            break;
          }
        }

        if (selectedEndPosition >= 0) {
          endPosition = selectedEndPosition + endTextHighlight.length;

          // endPosition = selectedStartPosition + 3000;
        } else {
          endPosition =
            selectedStartPosition +
            startTextHighlight.length +
            highlightSpan.length;
        }
      } else {
        endPosition =
          selectedStartPosition +
          startTextHighlight.length +
          highlightSpan.length;
      }

      var endHighlightSpan = '</div><!--End of appHighlight-->';

      sectionHTML =
        sectionHTML.substr(0, endPosition) +
        endHighlightSpan +
        sectionHTML.substr(endPosition);
    }

    return sectionHTML;
  }

  getIndicesOf(searchStr: string, str: string, caseSensitive: boolean) {
    if (searchStr == null) {
      return [];
    }

    var searchStrLen = searchStr.length;
    if (searchStrLen == 0) {
      return [];
    }
    var startIndex = 0,
      index,
      indices = [];
    if (!caseSensitive) {
      str = str.toLowerCase();
      searchStr = searchStr.toLowerCase();
    }
    while ((index = str.indexOf(searchStr, startIndex)) > -1) {
      indices.push(index);
      startIndex = index + searchStrLen;
    }
    return indices;
  }

  getHtmlForTable(tableSection, GLID) {
    if (tableSection) {
      var type = tableSection['Type'];
      if (type == 'table') {
        var glTable = tableSection['rowColData'];
        var tableHTML = '<div  class="ACCETableContainer">';
        tableHTML += '<div class=GLTableTitle>' + tableSection.title + '</div>';

        var maxColCount = 1;
        glTable.forEach(function (entry) {
          if (entry.columns.length > maxColCount) {
            maxColCount = entry.columns.length;
          }
        });
        var tableStyle = 'GLTable';
        if (maxColCount > 2) tableStyle = 'GLTable GLTableWide';

        //tableHTML += '<div class=ACCESecondColsDiv style="">';
        tableHTML +=
          '<ion-scroll has-bouncing="false" style="padding-bottom: 10px; " direction="x">';

        if (tableSection['figuretype']) {
          tableStyle += ' AACE' + tableSection['figuretype'];
        }
        tableHTML += '<table style=""  class="' + tableStyle + '">';

        for (var w = 0; w < glTable.length; ++w) {
          tableHTML += '<tr>';
          var aRow = glTable[w];

          for (var x = 0; x < aRow.columns.length; ++x) {
            var aCol = aRow.columns[x];
            //if (x==0 && aRow.columns.length>1)
            //    tableHTML += '<div class=\"col col-33 ALGCol\">' + aCol.text + '</div>';
            //else

            if (aCol.text.indexOf('Table 3.1</link>') != -1) {
              console.log(aCol.text);
            }
            if (aCol['rowspan']) {
              tableHTML +=
                '<td rowspan="' + aCol['rowspan'] + ' ">' + aCol.text + '</td>';
            } else if (aCol['colspan'].length > 0)
              tableHTML +=
                '<td colspan="' + aCol['colspan'] + ' ">' + aCol.text + '</td>';
            else tableHTML += '<td>' + aCol.text + '</td>';
          }
          tableHTML += '</tr>';
        }
        tableHTML += '</table>';

        tableHTML +=
          '<div class=AACETableTitle>' + tableSection['table_note'] + '</div>';

        tableHTML += '</ion-scroll>';
        //tableHTML += '</div>';

        tableHTML += '</div>';

        return tableHTML;
      } else if (type == 'DrugTable') {
        var tableHTML = this.tableAndBoxesReverse(
          tableSection,
          799,
          1,
          GLID,
          true,
          false,
          undefined,
        );
        return tableHTML;
      } else {
        alert('unknown table type!');
      }
      return '';
    }
  }

  getDrugTable(drugGuideline) {
    var results = [];
    if ('sections' in drugGuideline) {
      for (var i = 0; i < drugGuideline.sections.length; ++i) {
        var section = drugGuideline.sections[i];
        if (section['TableAndBoxes']) {
          for (var x = 0; x < section['TableAndBoxes'].length; x++) {
            var aTable = section['TableAndBoxes'][x];
            if (aTable['Type'] == 'DrugTable')
              results.push(section['TableAndBoxes'][x]);
          }
        }
      }
      return results;
    }
  }

  getAllTables(drugGuideline) {
    var results = [];
    if ('sections' in drugGuideline) {
      for (var i = 0; i < drugGuideline.sections.length; ++i) {
        var section = drugGuideline.sections[i];
        if (section['TableAndBoxes']) {
          for (var x = 0; x < section['TableAndBoxes'].length; x++) {
            results.push(section['TableAndBoxes'][x]);
          }
        }
      }
      return results;
    }
  }

  tableAndBoxesReverse(
    tableAndBoxes,
    width,
    pos,
    guideLineID,
    showTitle,
    reverse,
  ) {
    var FEED2 = false;
    if (tableAndBoxes['FEED2'] === 'YES') FEED2 = true;
    var glTable = tableAndBoxes['rowColData'];

    // special reorder based on problems is order
    this.reorderDrugTable(glTable);

    var tableHTML = '';
    var numberOfColums = glTable.length;
    var selectedAge = {
      name: 'All',
      age: 'All',
      months: -1,
      PFA: 'none',
      range: '',
    };

    var pageForAge = this.isPageForAge(guideLineID);

    if (selectedAge.months >= 0) numberOfColums = 2;

    if (pageForAge) {
      // override selectedAge as it's just one age
      numberOfColums = glTable.length;
    }

    if (numberOfColums > 8) {
      numberOfColums = 'Wide';
      width = 799;
    }

    tableHTML +=
      '<div class=AACEDrugTableContainer id=' +
      guideLineID +
      'dosageTable' +
      tableAndBoxes['ID'] +
      '>';

    if (numberOfColums > 8) numberOfColums = 'Wide';

    var ageRangeCol = 0;

    if (showTitle) {
      //tableHTML += '<div class=AACETableTitle>' + tableAndBoxes['publ_section_title'] + '</div>';

      tableHTML +=
        '<div class=AACETableTitle>' + tableAndBoxes['title'] + '</div>';
    }
    //else
    //    tableHTML += '<div class=AACETableTitle id=dosageTable' + pos + '>' + tableAndBoxes.title + '</div>';

    var idAdded = false;
    //if (width < 800) {
    tableHTML +=
      '<ion-scroll style="padding-bottom: 15px;" has-bouncing="false" direction="x">';
    idAdded = true;
    //}

    var rowsAdded = 0;

    //tableHTML += '<div class="ACCEPFAtable card">';
    //tableHTML += tableAndBoxes[0].title;
    //< algorithm data = "data" > < / algorithm >

    var firstRow = glTable[0];
    var skipRowPos = 0;
    if (
      firstRow.columns[0].text == '999' ||
      firstRow.columns[0].text == '9999'
    ) {
      skipRowPos = 1;
    }

    // SWAST annotation
    var drugName = this.columForDataType(firstRow.columns, 'drug-name').text;
    var drugMonths = 'PFA-ignore';
    if (!pageForAge) {
      drugMonths = this.columForDataType(firstRow.columns, 'drug-months').text;
    }

    // SWAST annotation

    var annotationText = this.drugDoseAnnotation(drugName, drugMonths);
    if (annotationText) {
      tableHTML +=
        '<div class=SWASTNoteTitle>' +
        annotationText +
        '</div><table class="ACCEDrugTablePFA' +
        numberOfColums +
        ' SWASTTable' +
        '">';
    } else {
      tableHTML += '<table class=ACCEDrugTablePFA' + numberOfColums + '>';
    }
    var notes = [];

    if (FEED2) {
      var columnsToUseInOrder = [];
      var hasNoteText = this.doesTableHaveNoteText(glTable);
      var hasWeight = this.doesTableHaveWeightText(glTable);
      skipRowPos = 0;
      if (tableAndBoxes['figuretype'] === 'dosage8') {
        columnsToUseInOrder = [
          'drug-age',
          'drug-initialdose',
          'drug-repeatdose',
          'drug-repeatinterval',
          'drug-concentration',
          'drug-volume',
          'drug-max-dose',
        ];
        if (hasNoteText) {
          columnsToUseInOrder.push('drug-note');
        }
        if (hasWeight) {
          columnsToUseInOrder.splice(1, 0, 'drug-weight');
        }
      }
      if (tableAndBoxes['figuretype'] === 'dosage9') {
        columnsToUseInOrder = [
          'drug-age',
          'drug-weight',
          'drug-initialdose',
          'drug-repeatdose',
          'drug-repeatinterval',
          'drug-concentration',
          'drug-volume',
          'drug-max-dose',
        ];
        if (hasNoteText) {
          columnsToUseInOrder.push('drug-note');
        }
      }
      if (
        tableAndBoxes['figuretype'] === 'quickref' ||
        tableAndBoxes['figuretype'] === 'intravascular' ||
        tableAndBoxes['figuretype'] === 'reversal' ||
        tableAndBoxes['figuretype'] === 'cardiac'
      ) {
        columnsToUseInOrder = [
          'drug-name',
          'drug-route',
          'drug-initialdose',
          'drug-repeatdose',
          'drug-repeatinterval',
          'drug-concentration',
          'drug-volume',
          'drug-max-dose',
        ];
        if (hasNoteText) {
          columnsToUseInOrder.push('drug-note');
        }
        skipRowPos = 0;
      }
      if (!reverse) {
        for (var w = skipRowPos; w < columnsToUseInOrder.length; w++) {
          tableHTML += '<tr >';

          for (var z = 0; z < glTable.length; z++) {
            var aRow = glTable[z];
            var theRange = -1;

            var drugName = this.columForDataType(
              aRow.columns,
              'drug-name',
            ).text;
            var drugMonthsObj = this.columForDataType(
              aRow.columns,
              'drug-months',
            );

            var drugMonths = '';
            if (drugMonthsObj) drugMonths = drugMonthsObj.text;
            var annotationText = this.drugDoseAnnotation(drugName, drugMonths);
            var aCol = this.columForDataType(
              aRow.columns,
              columnsToUseInOrder[w],
            );

            theRange = aRow.columns[0].text;

            if (aCol) {
              if (pageForAge || this.isDateInRange(theRange, selectedAge)) {
                if (w == skipRowPos) {
                  // tableHTML += '<th>';
                  if (annotationText && z > 0) {
                    // tableHTML += '<td class='+$rootScope.iCPGData.TrustName+'Anno>';
                    // tableHTML +='<div class='+$rootScope.iCPGData.TrustName+'DrugAnnoText >'+annotationText+'</div>';
                  } else
                    tableHTML +=
                      '<th ng-click="tableRowDrug(\'' +
                      tableAndBoxes['ID'] +
                      "'," +
                      w +
                      ',' +
                      z +
                      ')">';
                  // tableHTML += '<th>';
                  var columnText = this.removeNote(aCol.text, notes);
                  tableHTML += columnText;
                  tableHTML += '</th>\n';
                } else {
                  // tableHTML += '<td>';
                  tableHTML +=
                    '<td ng-click="tableRowDrug(\'' +
                    tableAndBoxes['ID'] +
                    "'," +
                    w +
                    ',' +
                    z +
                    ')">';
                  var columnText = this.removeNote(aCol.text, notes);
                  tableHTML += columnText;
                  tableHTML += '</td>\n';
                }
                if (z > 0) rowsAdded++;
              }
            }
          }
          tableHTML += '</tr>\n';
        }
      } else {
        // other way round
        //console.log('aGuidelinetable');
        for (var w = 0; w < glTable.length; ++w) {
          var aRow = glTable[w];
          var aCol = this.columForDataType(
            aRow.columns,
            columnsToUseInOrder[x],
          );
          var aDrugName = this.columForDataType(aRow.columns, 'drug-name');
          var drugMonths = this.columForDataType(aRow.columns, 'drug-months');
          var annotationText = null;
          if (aDrugName && drugMonths) {
            annotationText = this.drugDoseAnnotation(
              aDrugName.text,
              drugMonths.text,
            );
          } else console.log('!(aDrugName && drugMonths)');
          if (annotationText) {
            tableHTML +=
              '<tr class="SWAFTPFARow" ng-click="tableRow(\'' +
              tableAndBoxes['ID'] +
              "'," +
              w +
              ')">';
          } else {
            tableHTML +=
              '<tr ng-click="tableRow(\'' +
              tableAndBoxes['ID'] +
              "'," +
              w +
              ')">';
          }
          for (var x = 0; x < columnsToUseInOrder.length; ++x) {
            var skipThisCol = false;
            // if (columnsToIgnore.includes(aRow.columns[x].dataType))
            //     skipThisCol = true;
            // if (!skipThisCol) {
            var aCol = this.columForDataType(
              aRow.columns,
              columnsToUseInOrder[x],
            );
            if (aCol) {
              if (w == 0) {
                tableHTML += '<th>';
                var columnText = this.removeNote(aCol.text, notes);
                tableHTML += columnText;
                tableHTML += '</th>\n';
              } else {
                // if (x == 0)
                //     tableHTML += '<td class="blueUnderLine">';
                // else
                //     tableHTML += '<td>';
                if (annotationText && x === 0) {
                  // tableHTML += '<td class='+$rootScope.iCPGData.TrustName+'Anno>';
                  // tableHTML +='<div class='+$rootScope.iCPGData.TrustName+'DrugAnnoTextPFA >'+annotationText+'</div>';
                } else tableHTML += '<td>';
                var columnText = this.removeNote(aCol.text, notes);
                tableHTML += columnText;
                tableHTML += '</td>\n';
              }
            }
          }
          tableHTML += '</tr>\n';
          rowsAdded++;
        }
      }
    } else {
      // NOT FEED2 old table data format.
      if (!reverse) {
        for (var w = skipRowPos; w < firstRow.columns.length; w++) {
          tableHTML += '<tr>';

          for (var z = 0; z < glTable.length; z++) {
            var aRow = glTable[z];
            var theRange = -1;

            if (skipRowPos > 0) {
              theRange = aRow.columns[0].text;
            }
            if (pageForAge || this.isDateInRange(theRange, selectedAge)) {
              if (w == skipRowPos) {
                tableHTML += '<th>';
                var columnText = this.removeNote(aRow.columns[w].text, notes);
                tableHTML += columnText;
                tableHTML += '</th>\n';
              } else {
                tableHTML += '<td>';
                var columnText = this.removeNote(aRow.columns[w].text, notes);
                tableHTML += columnText;
                tableHTML += '</td>\n';
              }
              if (z > 0) rowsAdded++;
            }
          }
          tableHTML += '</tr>\n';
        }
      } else {
        // other way round
        //console.log('aGuidelinetable');
        for (var w = 0; w < glTable.length; ++w) {
          var aRow = glTable[w];

          tableHTML +=
            '<tr ng-click="tableRow(\'' +
            tableAndBoxes['ID'] +
            "'," +
            w +
            ')">';
          for (var x = 0; x < aRow.columns.length; ++x) {
            if (w == 0) {
              tableHTML += '<th>';
              var columnText = this.removeNote(aRow.columns[x].text, notes);
              tableHTML += columnText;
              tableHTML += '</th>\n';
            } else {
              if (x == 0) tableHTML += '<td class="blueUnderLine">';
              else tableHTML += '<td>';
              var columnText = this.removeNote(aRow.columns[x].text, notes);
              tableHTML += columnText;
              tableHTML += '</td>\n';
            }
          }
          tableHTML += '</tr>\n';
          rowsAdded++;
        }
      }
    }
    if (rowsAdded == 0) {
      // no matching data for age group??
      var pointToReplace = tableHTML.indexOf('<table class=ACCEDrugTablePFA');
      tableHTML = tableHTML.substring(0, pointToReplace);
      tableHTML +=
        '<div>It is NOT recommended to administer this drug to a person in the age range you have selected. See the full guideline for age restrictions</div>';
    } else {
      tableHTML += '</table>';
      tableHTML +=
        '<div class=AACETableTitle>' + tableAndBoxes['table_note'] + '</div>';
    }
    //if (width < 800)
    tableHTML += '</ion-scroll>';

    if (notes.length > 0) {
      tableHTML += '<div class=AACEDrugTableNotes>NOTES: ';
      for (var i = 0; i < notes.length; i++) {
        tableHTML += notes[i];
      }
      tableHTML += '</div>';
    }
    tableHTML += '</div>';
    return tableHTML;
  }

  doesTableHaveWeightText(glTable) {
    var result = false;

    try {
      for (var i = 0; i < glTable.length; i++) {
        var entry = glTable[i];
        var columns = entry.columns;
        columns.forEach(function (aColumn) {
          if (aColumn['dataType'] === 'drug-weight') {
            if (aColumn['text'].length > 0 && aColumn['text'] !== 'WEIGHT') {
              result = true;
            }
          }
        });
        if (result) break;
      }
    } catch (e) {
      console.log(e);
    }
    return result;
  }

  doesTableHaveNoteText(glTable) {
    var result = false;

    try {
      for (var i = 0; i < glTable.length; i++) {
        var entry = glTable[i];
        var columns = entry.columns;
        columns.forEach(function (aColumn) {
          if (aColumn['dataType'] === 'drug-note') {
            if (aColumn['text'].length > 0 && aColumn['text'] !== 'NOTE') {
              result = true;
            }
          }
        });
        if (result) break;
      }
    } catch (e) {}
    return result;
  }

  drugDoseAnnotation(drugName, drugMonths) {
    return null;
  }

  isDateInRange(theRange, theAge) {
    return true;

    if (theRange == -1) return true;
    var div = document.createElement('div');

    var result = false;
    div.innerHTML = theRange;
    var theRangeStr = div.textContent || div.innerText || '';

    try {
      var rangeValue = theRange.split('-');
      var minRange = parseInt(rangeValue[0]);
      var maxRange = parseInt(rangeValue[1]);

      var theAgeRangeValue = theAge.range.split('-');
      var theAgeMinRange = parseInt(theAgeRangeValue[0]);
      var theAgeMaxRange = parseInt(theAgeRangeValue[1]);

      if (theAgeMinRange >= minRange) {
        if (theAgeMaxRange <= maxRange) {
          result = true;
        }
      }
    } catch (e) {
      // statements to handle any unspecified exceptions
      console.log(e);
    }

    if (theAge.months == '-1') result = true;
    if (theRangeStr == '999' || theRangeStr == '9999')
      // it the title
      result = true;

    return result;
  }

  columForDataType(columns, adataType) {
    try {
      for (var w = 0; w < columns.length; ++w) {
        var aCol = columns[w];
        if (aCol['dataType'] === adataType) {
          return aCol;
        }
      }
    } catch (e) {
      console.log(e);
    }
    return null;
  }

  removeNote(columnText, notes) {
    var result = columnText;
    var note = this.getElement(columnText, 'note');
    var noteRef = this.getElement(columnText, 'note_ref');

    var count = 0;
    while (note.length > 0) {
      if (noteRef.length > 0) {
        notes.push(noteRef);
      }
      notes.push(note);
      result = result.replace(note, '');
      note = this.getElement(result, 'note');
      if (note.length > 0) {
        //console.log('noteRef.length > 0');
        var nextNoteRef = result.slice(
          result.indexOf(noteRef) + noteRef.length,
        );
        noteRef = this.getElement(nextNoteRef, 'note_ref');
      }
      count++;
      //return results2;
    }

    return result;
  }

  // this is simple no duplicated support
  getElement(htmlText, elementName) {
    var startString = '<' + elementName + '>';
    var startpos = htmlText.indexOf(startString);
    if (startpos == -1) {
      startString = '<' + elementName + ' ';
      startpos = htmlText.indexOf(startString);
    }
    var endString = '</' + elementName + '>';
    var endpos = htmlText.indexOf(endString);
    if (startpos != -1 && endpos != -1 && endpos > startpos) {
      var result = htmlText.substring(startpos, endpos + endString.length);
      return result;
    }
    return '';
  }

  reorderDrugTable(drugRowsCols) {
    try {
      var last = drugRowsCols[drugRowsCols.length - 1];
      if (last['columns'][0].text == '9999') {
        drugRowsCols.unshift(drugRowsCols.pop());
      }
    } catch (e) {
      console.log(e);
    }
  }

  isPageForAge(guideLineID) {
    var result = guideLineID.indexOf('P0') == 0;
    return result;
  }

  getAACETable(figureid) {
    var guidelineContents = this.state.guidelineContents;

    var tableSection = this.getFigureSectionByID(figureid, guidelineContents);

    var tableHtml = this.getHtmlForTable(tableSection, this.state.guidelineID);

    return tableHtml;
  }

  getFigureSectionByID(figureID, GLData) {
    for (var i = 0; i < GLData.sections.length; ++i) {
      var section = GLData.sections[i];

      if ('TableAndBoxes' in section) {
        for (var i1 = 0; i1 < section.TableAndBoxes.length; ++i1) {
          var figureSection = section.TableAndBoxes[i1];
          //if (figureSection['Type'] == 'figure') {
          if (
            figureSection['cpg-figurecaptionID'] == figureID ||
            figureSection['ID'] == figureID
          ) {
            return figureSection;
          }

          //}
        }
      }
    }
    return null;
  }

  getAACEFigure(figureID, GLData) {
    var figureSection = this.getFigureSectionByID(figureID, GLData);

    if (figureSection) {
      //create the html
      //add image link and captions
      var htmlText = '<div class="AACEFigure">';

      // console.log('getting image source');
      // console.log(figureSection);

      var src = 'img/GLimage/' + figureSection.image;

      htmlText +=
        '<img class="img11" src=' +
        src +
        ' ng-click="figureImageClick(\'' +
        figureSection['image'] +
        '\')" >';

      var captions = figureSection['caption'];
      htmlText += '<div class=AACEFigureCaption>' + captions + '</div>';
      var showALGButton = false;
      // if (figureSection['LinkRef']) {
      //     var algorithmData = this.algorithmforID(figureSection['LinkRef']);
      //     if (algorithmData != null)
      //         showALGButton = true;
      // }
      if (showALGButton) {
        htmlText += '<div class="col text-center">';
        htmlText +=
          '<div class="button button-outline button-positive" ng-click="gotoFig(' +
          figureSection['LinkRef'] +
          ')">Algorithm step by step</div>';
        htmlText += '</div>';
      }
      htmlText += '</div>';

      return htmlText;
    }
    return null;
  }

  render() {
    var baseUrl = 'web/';

    if (Platform.OS === 'android') {
      baseUrl = 'file:///android_asset/';
    }

    var shouldScalePageToFit = false;

    if (Platform.OS === 'android') {
      shouldScalePageToFit = true;
    }

    var webContentLayout = (
      <AMWebView
        style={{flex: 1}}
        source={{html: this.state.html, baseUrl: baseUrl}}
        originWhitelist={['file://']}
        useWebKit={true}
        scalesPageToFit={shouldScalePageToFit}
        allowsFullscreenVideo={true}
        javaScriptEnabled={true}
      />
    );

    if (Platform.OS === 'web') {
      webContentLayout = (
        <div
          className="content"
          dangerouslySetInnerHTML={{__html: this.state.html}}></div>
      );
    }

    return (
      <ScreenComponent style={[styles.container]}>
        {webContentLayout}
      </ScreenComponent>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
});
