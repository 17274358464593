/**
 * PPP
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import {Platform} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import AuthenticationController from './AuthenticationController';
import DataController from './DataController';
import RNFS from './RNFS';
import RNFetchBlob from '../classes/RNFetchBlob';
import GuidelinesController from './GuidelinesController';
// import { file } from '@babel/types';
var moment = require('moment');

export default {
  serverURL() {
    return 'https://aimermedia-api.co.uk';
    // return 'http://192.168.1.207:3001';
    // return 'http://localhost:3001';
  },

  async customFetch(url, options = {}) {
    try {
      const isAndroid = Platform.OS === 'android';
      const method = options.method || 'GET';

      if (isAndroid) {
        const response = await RNFetchBlob.fetch(
          method,
          url,
          options.headers,
          options.body,
        );
        return {...(response || {}), status: response?.respInfo?.status};
      }

      const response = await fetch(url, options);

      return response;
    } catch (error) {
      console.log('Error fetching: ', error);
      throw error;
    }
  },

  async getExistingPromoData(contactID: string) {
    try {
      const promoType = 'NEAS Greggs';
      const url = this.serverURL() + '/classapi/pppapi/getExistingPromoCode';
      const token = await AsyncStorage.getItem('token');
      if (token == null || token === '') {
        console.log('Error getting promo Data: No token provided');
        AuthenticationController.logOut();
      }

      const response = await this.customFetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          contactID,
          token,
          promoType,
        }),
      });

      const data = await response.json();
      return data.body;
    } catch (error) {
      console.log('getExistingPromoData error', error);
    }
  },

  async getNewPromoData(contactID: string) {
    try {
      const promoType = 'NEAS Greggs';
      const url = this.serverURL() + '/classapi/pppapi/getNewPromoCode';

      const token = await AsyncStorage.getItem('token');
      if (token == null || token === '') {
        console.log('Error getting promo Data: No token provided');
        AuthenticationController.logOut();
      }

      const response = await this.customFetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          contactID,
          token,
          promoType,
        }),
      });

      const data = await response.json();
      return data.body;
    } catch (error) {
      console.log('getNewPromoData', error);
    }
  },

  async migrateCPDResults(user: Object) {
    return new Promise(async (resolve, reject) => {
      try {
        console.log('migrateCPDResults');

        var url = this.serverURL() + '/classapi/pppapi/migrateCPDResults';

        var token = await AsyncStorage.getItem('token');

        if (token == null || token === '') {
          console.log('Error migrating results: No token provided');
          AuthenticationController.logOut();
        }

        var plusTrusts = user.plusTrusts.join(',');

        if (user.plusTrusts.length === 0) {
          plusTrusts = 'JRCALC';
        }

        var response = await this.customFetch(url, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            contactID: user.uid,
            token: token,
            trusts: plusTrusts,
          }),
        });

        if (response.status === 401) {
          AuthenticationController.logOut();
          reject('unauthorised');
        }

        let responseJson = await response.json();

        if (responseJson != null && responseJson.success === true) {
          resolve(responseJson);
        } else {
          resolve(responseJson);
        }
      } catch (error) {
        console.log(error);
      }

      resolve({});
    });
  },

  async downloadItemsModifiedSinceDate(
    name: string,
    date: number,
    contactID: string,
    currentCount: number,
  ) {
    return new Promise(async (resolve, reject) => {
      var url = this.serverURL() + '/classapi/pppapi/getItemsModifiedSinceDate';

      var token = await AsyncStorage.getItem('token');

      if (token == null || token === '') {
        console.log('Error downloading items: No token provided');
        reject('No token provided');
        AuthenticationController.logOut();
      }

      var response = null;

      try {
        response = await this.customFetch(url, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: name,
            lastModifiedDate: date,
            token: token,
            contactID: contactID,
            appSupportsTrustQuizzes: true,
            currentCount: currentCount,
            apiCallVersion: 2,
          }),
        });

        if (response.status === 401) {
          AuthenticationController.logOut();
          reject('unauthorised');
        }
      } catch (error) {
        reject(error);
      }

      try {
        let responseJson = await response.json();

        if (responseJson.error != null) {
          console.log('Error downloading items: ', responseJson.error);
          reject(responseJson.error);
        } else {
        }
        let messageBody = responseJson.body;

        // var items = responseJson.body.items;
        // var lastDownloadedDate = responseJson.body.lastDownloadedDate;

        resolve(messageBody);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  },

  async getExtraResources() {
    try {
      const res = await fetch(
        'https://aace.s3.amazonaws.com/furtherResources.json',
      );
      if (res.ok) {
        // const json = await res.json();
        return await res.json();
      }
    } catch (error) {
      console.log('Failed to get resources');
      return [];
    }
  },

  async downloadFeaturedCards() {
    try {
      const promoFeaturedCardsUrl =
        'https://ppp-temp-files.s3.amazonaws.com/data/pppFeaturedCardsPromo.json';
      const featuredCardsUrl =
        'https://ppp-temp-files.s3.amazonaws.com/data/pppFeaturedCards.json';

      const [featuredResponse, promoResponse] = await Promise.allSettled([
        this.customFetch(featuredCardsUrl, {
          headers: {'Cache-Control': 'no-cache'},
        }),
        this.customFetch(promoFeaturedCardsUrl, {
          headers: {'Cache-Control': 'no-cache'},
        }),
      ]);

      const featuredCardsJson =
        featuredResponse.status === 'fulfilled'
          ? await featuredResponse.value.json()
          : [];

      const promoFeaturedCardsJson =
        promoResponse.status === 'fulfilled'
          ? await promoResponse.value.json()
          : [];

      const allFeaturedCards = [
        ...promoFeaturedCardsJson,
        ...featuredCardsJson,
      ];

      DataController.saveDataToFile(allFeaturedCards, 'pppFeaturedCards.json');

      return allFeaturedCards;
    } catch (error) {
      console.error('An unexpected error occurred:', error);
      throw error;
    }
  },

  async downloadDidYouKnowItems() {
    return new Promise(async (resolve, reject) => {
      try {
        let didYouKnowUrl =
          'https://ppp-temp-files.s3.amazonaws.com/data/pppDidYouKnow.json';

        var response = await this.customFetch(didYouKnowUrl, {
          headers: {'Cache-Control': 'no-cache'},
        });
        // console.log(response);
        let didYouKnowItemsJson = await response.json();

        await DataController.saveDataToFile(
          didYouKnowItemsJson,
          'pppDidYouKnow.json',
        );

        resolve(didYouKnowItemsJson);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  },

  async downloadPromotions() {
    try {
      const promotionsUrl =
        'https://ppp-temp-files.s3.amazonaws.com/data/promotions.json';
      const response = await this.customFetch(promotionsUrl, {
        headers: {'Cache-Control': 'no-cache'},
      });
      // [{
      //     "EndDate": Date,
      //     "FinalDate": Date,
      //     "PromoType": "Greggs",
      //     "StartDate": Date,
      //     "Trust": "NEAS",
      //     "Live": boolean
      // }]
      const data = await response.json();
      console.log('Getting Promotions:', data.length);
      await DataController.saveDataToFile(data, 'promotions.json');
      return data;
    } catch (error) {
      console.log('Failed to get promotions');
      return [];
    }
  },

  async downloadVideosAndPodcasts() {
    return new Promise(async (resolve, reject) => {
      try {
        let videosAndPodcastsUrl =
          'https://ppp-temp-files.s3.amazonaws.com/data/pppVideoPodcasts.json';

        var response = await this.customFetch(videosAndPodcastsUrl, {
          headers: {'Cache-Control': 'no-cache'},
        });
        // console.log(response);
        let videosAndPodcastsJson = await response.json();

        await DataController.saveDataToFile(
          videosAndPodcastsJson,
          'pppVideoPodcasts.json',
        );

        resolve(videosAndPodcastsJson);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  },

  async downloadDailyChallenge(contactID: string) {
    return new Promise(async (resolve, reject) => {
      try {
        // get latest trust guidelines list from server
        var url = this.serverURL() + '/classapi/pppapi/getDailyChallenge';

        var token = await AsyncStorage.getItem('token');

        if (token == null || token === '') {
          console.log('Error getting items: No token provided');
          AuthenticationController.logOut();
          reject('No token provided');
        }

        var response = await this.customFetch(url, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            token: token,
            contactID: contactID,
          }),
        });

        if (response.status === 401) {
          AuthenticationController.logOut();
          reject('unauthorised');
        }

        let responseJson = await response.json();

        if (responseJson.error != null) {
          console.log('Error getting daily challenge: ', responseJson.error);
          reject(responseJson.error);
        }

        let messageBody = responseJson.body;

        resolve(messageBody);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  },

  async downloadLatestTrustGuidelinesList(
    trusts,
    contactID: string,
    test: boolean,
  ) {
    return new Promise(async (resolve, reject) => {
      try {
        // get latest trust guidelines list from server
        var url = this.serverURL() + '/classapi/pppapi/getTrustGuidelinesList';

        var token = await AsyncStorage.getItem('token');

        if (token == null || token === '') {
          console.log('Error getting items: No token provided');
          AuthenticationController.logOut();
          reject('No token provided');
        }

        var response = await this.customFetch(url, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            token: token,
            contactID: contactID,
            trusts: trusts,
            debug: test,
          }),
        });

        if (response.status === 401) {
          AuthenticationController.logOut();
          reject('unauthorised');
        }

        let responseJson = await response.json();

        if (responseJson.error != null) {
          console.log(
            'Error getting latest trust guidelines list: ',
            responseJson.error,
          );
          reject(responseJson.error);
        }

        let messageBody = responseJson.body;
        var serverTrustGuidelines = messageBody.items;

        // save server trust guidelines locally for next time
        try {
          await DataController.saveDataToFile(
            serverTrustGuidelines,
            'trustGuidelinesList.json',
          );
        } catch (error) {
          // Error saving data
          console.log('error saving trustGuidelinesList: ' + error);
        }

        resolve(messageBody);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  },

  async downloadLatestGuidelines(contactID: string) {
    try {
      const url = `${this.serverURL()}/classapi/pppapi/getGuidelinesList`;

      // Retrieve token using optional chaining and nullish coalescing for concise checks
      const token = (await AsyncStorage.getItem('token')) ?? '';
      if (!token) {
        console.error('Error getting items: No token provided');
        AuthenticationController.logOut();
        throw new Error('No token provided');
      }

      // Fetch the latest guidelines from the server
      const response = await this.customFetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({token, contactID}),
      });

      // Handle unauthorized response
      if (response.status === 401) {
        AuthenticationController.logOut();
        throw new Error('Unauthorized');
      }

      const {body: {items: serverGuidelines} = {}, error} =
        await response.json();

      if (error) {
        console.error('Error getting latest guidelines:', error);
        throw new Error(error);
      }

      // Save the server guidelines locally
      try {
        await DataController.saveDataToFile(
          serverGuidelines,
          'guidelinesList.json',
        );
      } catch (saveError) {
        console.error('Error saving guidelines list:', saveError);
      }

      // Identify which guidelines need to be downloaded
      const guidelinesToDownload = await this.getGuidelinesToDownload(
        serverGuidelines,
      );

      await Promise.all(
        guidelinesToDownload.map(guideline =>
          this.downloadAndUpdateGuideline(guideline),
        ),
      );

      return serverGuidelines;
    } catch (error) {
      console.error(error);
      throw error; // Re-throw the error for external handling
    }
  },

  // Helper function to get guidelines that need to be downloaded
  async getGuidelinesToDownload(serverGuidelines) {
    const guidelinesToDownload = [];

    for (const serverGuideline of serverGuidelines) {
      // Fetch the local guideline JSON by GLID
      const localGuidelineJSON =
        await GuidelinesController.getGuidelineJSONForGLID(
          serverGuideline.GLID,
        );

      const isMatchingGuideline =
        localGuidelineJSON &&
        localGuidelineJSON !== 'Old Guideline' &&
        localGuidelineJSON.GLID === serverGuideline.GLID;

      // If no matching local guideline or version mismatch, add to download list
      if (
        !serverGuideline?.remove &&
        (!isMatchingGuideline ||
          this.needsUpdate(serverGuideline, localGuidelineJSON))
      ) {
        guidelinesToDownload.push(serverGuideline);
      }

      // Check if any images are missing
      const missingImages = await this.checkMissingImages(
        serverGuideline.images,
      );
      if (missingImages && !guidelinesToDownload.includes(serverGuideline)) {
        guidelinesToDownload.push(serverGuideline);
      }
    }

    return guidelinesToDownload;
  },

  // Check if a server guideline needs an update compared to the local one
  needsUpdate(serverGuideline, localGuideline) {
    return (
      serverGuideline.publ_major_version_no >
        localGuideline.publ_major_version_no ||
      (serverGuideline.publ_major_version_no ===
        localGuideline.publ_major_version_no &&
        serverGuideline.publ_minor_version_no >
          localGuideline.publ_minor_version_no)
    );
  },

  // Helper function to check if any images are missing locally
  async checkMissingImages(images) {
    for (const image of images) {
      const exists = await DataController.checkGuidelineImageExists(
        image.imageName,
      );
      if (!exists) return true;
    }
    return false;
  },

  // Helper function to download and update a guideline
  async downloadAndUpdateGuideline(guideline) {
    const filename = `${guideline.GLID}.json`;
    const url = `https://aace.s3.amazonaws.com/${guideline.GLID}.${guideline.publ_major_version_no}.${guideline.publ_minor_version_no}.json`;

    // Download the guideline, fallback to alternative URL if needed
    try {
      console.log('Downloading guideline:', url);
      const response = await this.customFetch(url);
      const guidelineJson = await response.json();
      await DataController.saveDataToFile(guidelineJson, filename);
    } catch (error) {
      console.error('Error downloading guideline: ', url, error);
      await this.downloadFallbackGuideline(guideline, filename);
    }

    // Download images associated with the guideline
    await Promise.all(
      guideline.images.map(({imageName}) => this.downloadImage(imageName)),
    );
  },

  // Function to handle fallback download for guidelines
  async downloadFallbackGuideline(guideline, filename) {
    let baseUrl = 'https://jrcalc.s3.amazonaws.com/Feed-Sup/';
    const prefix = ['G', 'S'].includes(guideline.GLID[0])
      ? 'Guidelines/'
      : 'Drugs/';
    const url = `${baseUrl}${prefix}${filename}`;
    try {
      console.log('Downloading fallback guideline:', url);
      const response = await this.customFetch(url);
      const guidelineJson = await response.json();
      await DataController.saveDataToFile(guidelineJson, filename);
    } catch (error) {
      console.error('Error downloading fallback guideline:', url, error);
    }
  },

  // Function to download an image from the server
  async downloadImage(imageName) {
    const imageUrl = `https://aace.s3.amazonaws.com/${imageName}`;
    let response = await this.customFetch(imageUrl);

    // If the image does not exist at the primary URL, use the fallback
    if (!response.ok) {
      const altImageURL = `https://jrcalc.s3.amazonaws.com/Feed-Sup/GLimage/${imageName}`;
      await DataController.downloadImage(
        `GLImage/${imageName}`,
        altImageURL,
        0,
      );
    } else {
      await DataController.downloadImage(`GLImage/${imageName}`, imageUrl, 0);
    }
  },

  async downloadLatestTrustGuidelines(
    trusts,
    contactID: string,
    test: boolean,
  ) {
    return new Promise(async (resolve, reject) => {
      if (!trusts || trusts.length === 0) {
        resolve();
      }

      console.log('starting to get trust guidelines');

      // get latest trust guidelines list from server
      var url = this.serverURL() + '/classapi/pppapi/getTrustGuidelinesList';

      var token = await AsyncStorage.getItem('token');

      if (token == null || token === '') {
        console.log('Error getting items: No token provided');
        AuthenticationController.logOut();
        reject('No token provided');
      }

      var response = await this.customFetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: token,
          contactID: contactID,
          trusts: trusts,
          debug: test,
        }),
      });

      if (response.status === 401) {
        AuthenticationController.logOut();
        reject('unauthorised');
      }

      try {
        let responseJson = await response.json();

        if (responseJson.error != null) {
          console.log('Error getting trust guidelines: ', responseJson.error);
          reject(responseJson.error);
        }

        let messageBody = responseJson.body;
        var serverTrustGuidelines = messageBody.items;

        // save server trust guidelines locally for next time
        try {
          await DataController.saveDataToFile(
            serverTrustGuidelines,
            'trustGuidelinesList.json',
          );
        } catch (error) {
          // Error saving data
          console.log('error saving trustGuidelinesList.json: ' + error);
        }

        // console.log(messageBody);

        var trustGuidelinesToDownload = [];

        // compare with local guidelines list
        for (
          let serverTrustGuidelineIndex = 0;
          serverTrustGuidelineIndex < serverTrustGuidelines.length;
          serverTrustGuidelineIndex++
        ) {
          var serverTrustGuideline =
            serverTrustGuidelines[serverTrustGuidelineIndex];
          var matchingLocalTrustGuideline = null;

          let localTrustGuidelineJSON =
            await GuidelinesController.getTrustGuidelineJSONForGLID(
              serverTrustGuideline.GLID,
            );
          if (
            localTrustGuidelineJSON != null &&
            localTrustGuidelineJSON !== 'Old Guideline' &&
            localTrustGuidelineJSON.GLID === serverTrustGuideline.GLID
          ) {
            matchingLocalTrustGuideline = localTrustGuidelineJSON;
          }

          if (matchingLocalTrustGuideline != null) {
            if (
              matchingLocalTrustGuideline.publ_major_version_no !==
                serverTrustGuideline.publ_major_version_no ||
              matchingLocalTrustGuideline.publ_minor_version_no !==
                serverTrustGuideline.publ_minor_version_no
            ) {
              trustGuidelinesToDownload.push(serverTrustGuideline);
            }
          } else {
            trustGuidelinesToDownload.push(serverTrustGuideline);
          }
        }

        trustGuidelinesToDownload = trustGuidelinesToDownload.filter(
          it => !it.hasOwnProperty('caption'),
        );

        // download trust guidelines which aren't latest version
        for (
          let trustGuidelineToDownloadIndex = 0;
          trustGuidelineToDownloadIndex < trustGuidelinesToDownload.length;
          trustGuidelineToDownloadIndex++
        ) {
          var trustGuidelineToDownload =
            trustGuidelinesToDownload[trustGuidelineToDownloadIndex];

          let trustGuidelineJson = null;
          let filename = '';
          let isTestGuideline = false;
          try {
            var url = 'https://jrcalc.s3.amazonaws.com/' + trusts[0] + '/JSON/';

            filename = trustGuidelineToDownload.GLID + '.json';

            url += filename;

            var response = await this.customFetch(url);
            // console.log(response);
            trustGuidelineJson = await response.json();
          } catch (error) {
            try {
              var url =
                'https://aace.s3.amazonaws.com/' +
                trusts[0] +
                '/' +
                trustGuidelineToDownload.GLID +
                '.' +
                trustGuidelineToDownload.publ_major_version_no +
                '.' +
                trustGuidelineToDownload.publ_minor_version_no +
                '.json';

              filename = trustGuidelineToDownload.GLID + '.json';

              var response = await this.customFetch(url);
              trustGuidelineJson = await response.json();
              isTestGuideline = true;
            } catch (error) {
              console.log('error download or saving trust guideline');
            }
          }

          trustGuidelineJson = trustGuidelineJson.CMG;

          DataController.saveDataToFile(trustGuidelineJson, filename);

          // download images if any
          for (
            let imageIndex = 0;
            imageIndex < trustGuidelineToDownload.images.length;
            imageIndex++
          ) {
            var image = trustGuidelineToDownload.images[imageIndex];
            let imageUrl =
              'https://aace.s3.amazonaws.com/' +
              trusts[0] +
              '/' +
              image.imageName;
            if (isTestGuideline) {
            } else {
              imageUrl =
                'https://jrcalc.s3.amazonaws.com/' +
                trusts[0] +
                '/img/' +
                image.imageName;
            }

            console.log('downloading image: ', image.imageName);
            try {
              await DataController.downloadImage(
                'GLImage/' + image.imageName,
                imageUrl,
                0,
              );
            } catch (error) {
              console.log(error);
            }
          }
        }

        resolve(messageBody);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  },

  async downloadExcludedGuidelines(trusts, contactID: string) {
    return new Promise(async (resolve, reject) => {
      console.log('starting to get excluded guidelines');

      // get excluded guidelines list from server
      var url = this.serverURL() + '/classapi/pppapi/getExcludedGuidelines';

      var token = await AsyncStorage.getItem('token');

      if (token == null || token === '') {
        console.log('Error getting items: No token provided');
        AuthenticationController.logOut();
        reject('No token provided');
      }

      var response = await this.customFetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: token,
          contactID: contactID,
          trusts: trusts,
        }),
      });

      if (response.status === 401) {
        AuthenticationController.logOut();
        reject('unauthorised');
      }

      try {
        let responseJson = await response.json();

        if (responseJson.error != null) {
          console.log(
            'Error getting excluded guidelines: ',
            responseJson.error,
          );
          reject(responseJson.error);
        }

        let messageBody = responseJson.body;
        var excludedGuidelines = messageBody.items;

        // save server trust guidelines locally for next time
        try {
          await AsyncStorage.setItem(
            'excludedGuidelines',
            JSON.stringify(excludedGuidelines),
          );
        } catch (error) {
          // Error saving data
          console.log('error saving excluded guidelines: ' + error);
        }

        resolve(messageBody);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  },

  async downloadPlusTimeLineData(contactID: string) {
    console.log('starting to get plus timeline data');

    // get plus timeline data from server
    const url = 'https://pf.aimernginx.co.uk/portfolio/TL/getPlusTimeLineData';

    const token = await AsyncStorage.getItem('token');

    if (token == null || token === '') {
      console.log('Error getting items: No token provided');
      AuthenticationController.logOut();
    }

    try {
      const response = await this.customFetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          Token: token,
          ContactID: contactID,
        }),
      });

      if (response.status === 401) {
        AuthenticationController.logOut();
      }

      const responseJson = await response.json();

      if (responseJson.error != null) {
        console.log('Error getting plus timeline data: ', responseJson.error);
      }

      await AsyncStorage.setItem(
        'plusTimelineData',
        JSON.stringify(responseJson.body),
      );
    } catch (error) {
      console.log('Failed to load downloadPlusTimeLineData', error);
    }
  },

  // async downloadLatestPGDs(plusTrusts, contactID: string) {

  //   return new Promise(async (resolve, reject) => {

  //     console.log('starting to get PGDs');

  //     var localPGDs = [];

  //     try {
  //       localPGDs = await DataController.getDataFromFile('pgdList.json');
  //     } catch (error) {
  //       console.log("pgdList.json doesn't exist");
  //     }

  //     try {
  //       if (localPGDs == null || localPGDs === "") {
  //         localPGDs = [];
  //       }

  //     } catch (error) {
  //       console.log('Error parsing local guidelines: ', error);
  //     }

  //     // get latest pgd list from server
  //     var url = this.serverURL() + '/classapi/pppapi/getPGDList';

  //     var token = await AsyncStorage.getItem('token');

  //     if (token == null || token === '') {
  //       console.log('Error getting items: No token provided');
  //       AuthenticationController.logOut();
  //       reject('No token provided');
  //     }

  //     var response = await this.customFetch(url, {
  //       method: 'POST',
  //       headers: {
  //         Accept: 'application/json',
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({
  //         token: token,
  //         contactID: contactID,
  //         trusts: plusTrusts
  //       }),
  //     });

  //     if (response.status === 401) {
  //       AuthenticationController.logOut();
  //       reject('unauthorised');
  //     }

  //     try {

  //       let responseJson = await response.json();

  //       if (responseJson.error != null) {

  //         console.log('Error getting pgds: ', responseJson.error);
  //         reject(responseJson.error);
  //       }

  //       console.log(plusTrusts);

  //       let messageBody = responseJson.body;
  //       var serverPGDs = messageBody.items;

  //       console.log(messageBody);

  //       var pgdsToDownload = [];

  //       console.log('local pgds: ', localPGDs);

  //       // compare with local guidelines list
  //       for (let serverPGDIndex = 0; serverPGDIndex < serverPGDs.length; serverPGDIndex++) {

  //         var serverPGD = serverPGDs[serverPGDIndex];
  //         var matchingLocalPGD = null;

  //         for (let localPGDIndex = 0; localPGDIndex < localPGDs.length; localPGDIndex++) {
  //           var localPGD = localPGDs[localPGDIndex];
  //           if (localPGD.GLID === serverPGD.GLID) {
  //             matchingLocalPGD = localPGD;
  //             break;
  //           }
  //         }

  //         if (matchingLocalPGD != null) {

  //           if (localPGD.publ_major_version_no !== serverPGD.publ_major_version_no || localPGD.publ_minor_version_no !== serverPGD.publ_minor_version_no) {
  //             pgdsToDownload.push(serverPGD);
  //           }

  //         } else {
  //           pgdsToDownload.push(serverPGD);
  //         }
  //       }

  //       console.log('server pgds: ', serverPGDs);
  //       console.log('pgds to download: ', pgdsToDownload);

  //       // download pgds which aren't latest version
  //       for (let pgdToDownloadIndex = 0; pgdToDownloadIndex < pgdsToDownload.length; pgdToDownloadIndex++) {
  //         var pgdToDownload = pgdsToDownload[pgdToDownloadIndex];

  //         var url = 'https://jrcalc.s3.amazonaws.com/' + plusTrusts[0] + '/JSON/';

  //         var filename = pgdToDownload.GLID + '.json';

  //         url += filename;

  //         var response = await this.customFetch(url);
  //         console.log(response);
  //         let pgdJson = await response.json();
  //         pgdJson = pgdJson.CMG

  //         DataController.saveDataToFile(pgdJson, filename, true);
  //         console.log(pgdJson);

  //         // download images if any
  //         for (let imageIndex = 0; imageIndex < pgdToDownload.images.length; imageIndex++) {

  //           var image = pgdToDownload.images[imageIndex];
  //           var imageUrl = 'https://jrcalc.s3.amazonaws.com/' + plusTrusts[0] + '/img/' + image.imageName;

  //           console.log('downloading image: ', image.imageName);
  //           await DataController.downloadImage('GLImage/' + image.imageName, imageUrl, 0);
  //         }
  //       }

  //       // save server pgds locally for next time
  //       try {
  //         await DataController.saveDataToFile(serverPGDs, 'pgdList.json', true);
  //         // await AsyncStorage.setItem('guidelinesList', JSON.stringify(serverPGDs));
  //       } catch (error) {
  //         // Error saving data
  //         console.log('error saving pgdList: ' + error);
  //       }

  //       resolve(messageBody);

  //     } catch (error) {

  //       console.log(error);
  //       reject(error);
  //     }
  //   });
  // },

  async downloadLatestPGDAcknowledgements(plusTrusts, contactID: string) {
    return new Promise(async (resolve, reject) => {
      console.log('starting to get all PGD acknowledgements');

      if (plusTrusts == null || plusTrusts.length === 0) {
        resolve();
      }

      // get PGD acknowledgements list from server
      var url =
        'https://www.aimernginx.co.uk/aimerapi/class/PGD-server/getPGDs';

      var token = await AsyncStorage.getItem('token');

      if (token == null || token === '') {
        console.log('Error getting items: No token provided');
        AuthenticationController.logOut();
        reject('No token provided');
      }

      var response = await this.customFetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: token,
          wyvernID: parseInt(contactID),
          trust: plusTrusts[0],
          skipWyvern: true,
        }),
      });

      if (response.status === 401) {
        AuthenticationController.logOut();
        reject('unauthorised');
      }

      try {
        let responseJson = await response.json();

        if (responseJson.error != null) {
          console.log(
            'Error getting pgd acknowledgements: ',
            responseJson.error,
          );
          reject(responseJson.error);
        } else if (responseJson.success === false) {
          console.log(
            'Error getting pgd acknowledgements: ',
            responseJson.params,
          );
          reject('Error getting pgd acknowledgements');
        }

        let pgdAcknowledgements = responseJson.params;

        // save server pgds locally for next time
        try {
          await DataController.saveDataToFile(
            pgdAcknowledgements,
            'pgdAcknowledgements.json',
          );
        } catch (error) {
          // Error saving data
          console.log('error saving pgdAcknowledgements: ' + error);
        }

        resolve();
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  },

  async downloadLatestSinglePGDAcknowledgement(
    plusTrusts,
    contactID: string,
    pgdInfo: object,
  ) {
    return new Promise(async (resolve, reject) => {
      console.log('starting to get single PGD acknowledgement');

      if (
        plusTrusts == null ||
        plusTrusts.length === 0 ||
        pgdInfo == null ||
        pgdInfo.versionNumber == null
      ) {
        resolve();
      }

      // get PGD acknowledgement from server
      var url =
        'https://www.aimernginx.co.uk/aimerapi/class/PGD-server/getSinglePGD';

      var token = await AsyncStorage.getItem('token');

      if (token == null || token === '') {
        console.log('Error getting items: No token provided');
        AuthenticationController.logOut();
        reject('No token provided');
      }

      var response = await this.customFetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: token,
          wyvernID: parseInt(contactID),
          trust: plusTrusts[0],
          GLID: pgdInfo.GLID,
          title: pgdInfo.title,
          versionNumber: pgdInfo.versionNumber,
        }),
      });

      if (response.status === 401) {
        AuthenticationController.logOut();
        reject('unauthorised');
      }

      try {
        let responseJson = await response.json();

        if (responseJson.error != null) {
          console.log(
            'Error getting a single pgd acknowledgment: ',
            responseJson.error,
          );
          reject(responseJson.error);
        } else if (responseJson.success === false) {
          console.log(
            'Error getting single pgd acknowledgement: ',
            responseJson.params,
          );
          reject('Error getting pgd acknowledgements');
        }

        let updatedPGDAcknowledgement = responseJson.params;

        var allPGDAcknowledgements = await DataController.getDataFromFile(
          'pgdAcknowledgements.json',
        );
        var acknowledgementFound = false;

        for (
          let pgdAcknowledgementIndex = 0;
          pgdAcknowledgementIndex < allPGDAcknowledgements.length;
          pgdAcknowledgementIndex++
        ) {
          var pgdAcknowledgement =
            allPGDAcknowledgements[pgdAcknowledgementIndex];

          if (pgdAcknowledgement.GLID === updatedPGDAcknowledgement.GLID) {
            allPGDAcknowledgements[pgdAcknowledgementIndex] =
              updatedPGDAcknowledgement;
            acknowledgementFound = true;
            break;
          }
        }

        if (acknowledgementFound === false) {
          allPGDAcknowledgements.push(updatedPGDAcknowledgement);
        }

        // save pgd acknowledgements locally
        try {
          await DataController.saveDataToFile(
            allPGDAcknowledgements,
            'pgdAcknowledgements.json',
          );
        } catch (error) {
          // Error saving data
          console.log('error saving pgdAcknowledgements: ' + error);
        }

        resolve();
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  },

  async acknowledgePGD(plusTrusts, contactID: string, pgdInfo: object) {
    return new Promise(async (resolve, reject) => {
      console.log('starting to send pgd acknowledgement');

      if (plusTrusts == null || plusTrusts.length === 0) {
        resolve();
      }

      var url = 'https://www.aimernginx.co.uk/aimerapi/class/PGD-server/PGDAck';

      var token = await AsyncStorage.getItem('token');

      if (token == null || token === '') {
        console.log('Error getting items: No token provided');
        AuthenticationController.logOut();
        reject('No token provided');
      }

      var dateNow = new Date().toISOString();

      var response = await this.customFetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: token,
          wyvernID: parseInt(contactID),
          trust: plusTrusts[0],
          GLID: pgdInfo.GLID,
          title: pgdInfo.title,
          versionNumber: pgdInfo.versionNumber,
          timestamp: dateNow,
        }),
      });

      if (response.status === 401) {
        AuthenticationController.logOut();
        reject('unauthorised');
      }

      try {
        let responseJson = await response.json();

        if (responseJson.error != null) {
          console.log('Error acknowledging PGD: ', responseJson.error);
          reject(responseJson.error);
        } else if (responseJson.success === false) {
          console.log(
            'Error sending PGD acknowledgement: ',
            responseJson.params,
          );
          reject(
            "The PGD acknowledgement couldn't be sent. Please try again later",
          );
        }

        await this.downloadLatestSinglePGDAcknowledgement(
          plusTrusts,
          contactID,
          pgdInfo,
        );

        resolve();
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  },

  async downloadTrustInfo(plusTrusts, contactID: string) {
    return new Promise(async (resolve, reject) => {
      console.log('starting to get trust info');

      if (plusTrusts == null || plusTrusts.length === 0) {
        resolve();
      }

      // get PGD acknowledgements list from server
      var url = this.serverURL() + '/classapi/pppapi/getTrustInfo';

      var token = await AsyncStorage.getItem('token');

      if (token == null || token === '') {
        console.log('Error getting items: No token provided');
        AuthenticationController.logOut();
        reject('No token provided');
      }

      var response = await this.customFetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: token,
          contactID: parseInt(contactID),
          trusts: plusTrusts,
        }),
      });

      if (response.status === 401) {
        AuthenticationController.logOut();
        reject('unauthorised');
      }

      try {
        let responseJson = await response.json();

        if (responseJson.error != null) {
          console.log('Error getting trust info: ', responseJson.error);
          reject(responseJson.error);
        } else if (responseJson.success === false) {
          console.log('Error getting trust info: ', responseJson);
          reject('Error getting trust info');
        }

        // save server pgds locally for next time
        try {
          await DataController.saveDataToFile(
            responseJson.body.trustInfo,
            'pppTrustInfo.json',
          );
        } catch (error) {
          // Error saving data
          console.log('error saving trust info: ' + error);
        }

        resolve();
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  },

  async downloadAppFeatures(contactID: string, isPPPTester: boolean) {
    return new Promise(async (resolve, reject) => {
      try {
        if (contactID == null) {
          reject('no contact id');
        }

        let appFeaturesUrl = isPPPTester
          ? 'https://aace.s3.amazonaws.com/appFeatures.TEST.json'
          : 'https://aace.s3.amazonaws.com/appFeatures.json';

        var response = await this.customFetch(appFeaturesUrl, {
          headers: {'Cache-Control': 'no-cache'},
        });
        // console.log(response);
        let appFeaturesJson = await response.json();

        DataController.saveDataToFile(appFeaturesJson, 'appFeatures.json');

        resolve(appFeaturesJson);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  },

  async downloadQuizzesSinceDate(date: number, contactID: string) {
    return new Promise(async (resolve, reject) => {
      var url = this.serverURL() + '/classapi/pppapi/getQuizzesSinceDate';

      var token = await AsyncStorage.getItem('token');

      if (token == null || token === '') {
        console.log('Error getting items: No token provided');
        reject('No token provided');
        AuthenticationController.logOut();
      }

      var response = await this.customFetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          lastDownloadedDate: date,
          token: token,
          contactID: contactID,
        }),
      });

      try {
        let responseJson = await response.json();

        if (responseJson.error != null) {
          console.log('Error getting quizzes since date: ', responseJson.error);
          reject(responseJson.error);
        } else {
        }

        // var items = responseJson.body.items;
        // var lastDownloadedDate = responseJson.body.lastDownloadedDate;

        resolve(responseJson.body);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  },

  async createQuiz(
    name: string,
    contactID: string,
    quizMode: string,
    quizSet: string,
    questions: [],
    time: number,
  ) {
    return new Promise(async (resolve, reject) => {
      try {
        var url = this.serverURL() + '/classapi/pppapi/createQuiz';

        var token = await AsyncStorage.getItem('token');

        if (token == null || token === '') {
          console.log('Error getting items: No token provided');
          reject('No token provided');
          AuthenticationController.logOut();
        }

        var response = await this.customFetch(url, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: name,
            contactID: contactID,
            quizMode: quizMode,
            quizSet: quizSet,
            questions: questions.join(','),
            time: time,
            token: token,
          }),
        });

        if (response.status === 401) {
          AuthenticationController.logOut();
          reject('unauthorised');
        }

        let responseJson = await response.json();

        if (responseJson.error != null) {
          console.log('Error creating quiz: ', responseJson.error);
          reject(responseJson.error);
        } else {
        }

        resolve(responseJson);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  },

  async downloadResultsSinceDate(date: number, contactID: string) {
    return new Promise(async (resolve, reject) => {
      console.log('starting to get results since date', new Date(date));
      try {
        var url = this.serverURL() + '/classapi/pppapi/getResultsSinceDate';

        var token = await AsyncStorage.getItem('token');

        if (token == null || token === '') {
          console.log('Error getting items: No token provided');
          reject('No token provided');
          AuthenticationController.logOut();
        }

        var response = await this.customFetch(url, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            lastDownloadedDate: date,
            token: token,
            contactID: contactID,
          }),
        });

        if (response.status === 401) {
          AuthenticationController.logOut();
          reject('unauthorised');
        }

        let responseJson = await response.json();

        if (responseJson.error != null) {
          console.log('Error downloading results: ', responseJson.error);
          reject(responseJson.error);
        } else {
        }
        let messageBody = responseJson.body;

        // var items = responseJson.body.items;
        // var lastDownloadedDate = responseJson.body.lastDownloadedDate;

        resolve(messageBody);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  },

  async sendResult(
    contactID: string,
    answer: string,
    correct: boolean,
    packName: string,
    questionID: Number,
    quizName: string,
    time: number,
  ) {
    return new Promise(async (resolve, reject) => {
      try {
        var url = this.serverURL() + '/classapi/pppapi/createResult';

        var token = await AsyncStorage.getItem('token');

        if (token == null || token === '') {
          console.log('Error getting items: No token provided');
          reject('No token provided');
          AuthenticationController.logOut();
        }

        var response = await this.customFetch(url, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            contactID: contactID,
            answer: answer,
            correct: correct,
            packName: packName,
            questionID: questionID,
            quizName: quizName,
            time: time,
            token: token,
          }),
        });

        if (response.status === 401) {
          AuthenticationController.logOut();
          reject('unauthorised');
        }

        let responseJson = await response.json();

        if (responseJson.error != null) {
          console.log('Error sending result: ', responseJson.error);
          reject(responseJson.error);
        } else {
        }

        resolve(responseJson);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  },

  async getResultsQueue() {
    try {
      const resultQueueString = await AsyncStorage.getItem('ResultQueue');

      if (resultQueueString !== null) {
        var resultQueue = JSON.parse(resultQueueString);
        return resultQueue;
      }
    } catch (error) {}

    return [];
  },

  async addResultToQueue(result: Object) {
    var resultQueue = await this.getResultsQueue();
    resultQueue.push(result);
    var resultQueueString = JSON.stringify(resultQueue);

    try {
      await AsyncStorage.setItem('ResultQueue', resultQueueString);
    } catch (error) {
      // Error saving data
      console.log('error saving to Result Queue: ' + error);
    }
  },

  async removeResultFromQueue(result: Object) {
    var resultQueue = await this.getResultsQueue();

    for (let i = 0; i < resultQueue.length; i++) {
      var aResult = resultQueue[i];

      if (
        aResult.time === result.time &&
        aResult.contactID === result.contactID
      ) {
        resultQueue.splice(i, 1);
        break;
      }
    }

    // console.log('logging new result queue');
    // console.log(resultQueue);

    var resultQueueString = JSON.stringify(resultQueue);

    try {
      await AsyncStorage.setItem('ResultQueue', resultQueueString);
    } catch (error) {
      // Error saving data
      console.log('error saving to Result Queue: ' + error);
    }
  },

  async resendOutstandingResults() {
    var resultQueue = await this.getResultsQueue();

    for (var i = 0; i < resultQueue.length; i++) {
      var aResult = resultQueue[i];

      try {
        var response = await this.sendResult(
          aResult.contactID,
          aResult.answer,
          aResult.correct,
          aResult.packName,
          aResult.questionID,
          aResult.quizName,
          aResult.time,
        );

        // remove from queue once confirmed result is saved on server
        if (response != null) {
          if (response.success === true) {
            this.removeResultFromQueue(aResult);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  },

  async getQuizQueue() {
    try {
      const quizQueueString = await AsyncStorage.getItem('QuizQueue');

      if (quizQueueString !== null) {
        var quizQueue = JSON.parse(quizQueueString);
        return quizQueue;
      }
    } catch (error) {}

    return [];
  },

  async addQuizToQueue(quiz: Object) {
    var quizQueue = await this.getQuizQueue();
    quizQueue.push(quiz);
    var quizQueueString = JSON.stringify(quizQueue);

    try {
      await AsyncStorage.setItem('QuizQueue', quizQueueString);
    } catch (error) {
      // Error saving data
      console.log('error saving to Quiz Queue: ' + error);
    }
  },

  async removeQuizFromQueue(quiz: Object) {
    var quizQueue = await this.getQuizQueue();

    for (let i = 0; i < quizQueue.length; i++) {
      var aQuiz = quizQueue[i];

      if (aQuiz.time === quiz.time && aQuiz.contactID === quiz.contactID) {
        quizQueue.splice(i, 1);
        break;
      }
    }

    console.log('logging new quiz queue');

    var quizQueueString = JSON.stringify(quizQueue);

    try {
      await AsyncStorage.setItem('QuizQueue', quizQueueString);
    } catch (error) {
      // Error saving data
      console.log('error saving to Quiz Queue: ' + error);
    }
  },

  async resendOutstandingQuizzes() {
    var quizQueue = await this.getQuizQueue();

    console.log('resending outstanding quizzes');

    for (var i = 0; i < quizQueue.length; i++) {
      var aQuiz = quizQueue[i];

      var response = await this.createQuiz(
        aQuiz.name,
        aQuiz.contactID,
        aQuiz.quizMode,
        aQuiz.quizSet,
        aQuiz.questions,
        aQuiz.time,
      );

      console.log('create quiz response');
      // console.log(response);

      // remove from queue once confirmed quiz is saved on server
      if (response != null) {
        if (response.success === true) {
          this.removeQuizFromQueue(aQuiz);
        }
      }
    }
  },

  async downloadBookProgress(contactID: string) {
    return new Promise(async (resolve, reject) => {
      var url = this.serverURL() + '/classapi/pppapi/getBookProgress';

      var token = await AsyncStorage.getItem('token');

      if (token == null || token === '') {
        console.log('Error downloading items: No token provided');
        reject('No token provided');
        AuthenticationController.logOut();
      }

      var response = null;

      try {
        response = await this.customFetch(url, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            token: token,
            contactID: contactID,
          }),
        });

        if (response.status === 401) {
          AuthenticationController.logOut();
          reject('unauthorised');
        }
      } catch (error) {
        reject(error);
      }

      try {
        let responseJson = await response.json();

        if (responseJson.error != null) {
          console.log('Error downloading items: ', responseJson.error);
          reject(responseJson.error);
        } else {
        }
        let items = responseJson.items;

        if (items) {
          try {
            let names = [];
            for (let item of items) {
              if (item.name) {
                names.push(item.name);
              }
            }

            await DataController.saveDataToFile(names, 'bookProgress.json');
          } catch (error) {
            // Error saving data
            console.log('error saving book progress: ' + error);
          }
        }

        resolve(items);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  },

  async updateBookProgress(
    contactID: string,
    type: string,
    name: name,
    markAsRead: boolean,
  ) {
    return new Promise(async (resolve, reject) => {
      try {
        var url = this.serverURL() + '/classapi/pppapi/updateBookProgress';
        var token = await AsyncStorage.getItem('token');
        var parameters = {
          token: token,
          contactID: contactID,
          type: type,
          name: name,
          markAsRead: markAsRead,
        };

        let result = await this.sendRequest(
          'updateBookProgress',
          url,
          parameters,
          'POST',
          false,
        );
        let test = '';
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  },

  /** Use this function for sending any requests which need to be saved on the server (as they will get saved for resending if they fail). */
  async sendRequest(
    type: string,
    url: string,
    parameters: Object,
    httpMethod: string,
    resendingFromQueue: boolean,
  ) {
    try {
      let request = {
        type: type,
        url: url,
        parameters: parameters,
        httpMethod: httpMethod,
      };

      if (resendingFromQueue === false) {
        await this.addRequestToQueue(request);
      }

      var params = JSON.stringify(request.parameters);

      let response = await this.customFetch(request.url, {
        method: request.httpMethod,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: params,
      });

      try {
        const responseJson = await response.json();

        if (responseJson.success) {
          await this.removeRequestFromQueue(request);
        }

        if (responseJson.error !== undefined) {
          console.log('Error sending request: ', responseJson.error);
        }

        return responseJson;
      } catch (error) {
        console.log(error);
      }
    } catch (e) {
      console.log(e);
    }
  },

  async getRequestQueue() {
    try {
      const requestQueueString = await AsyncStorage.getItem('RequestQueue');

      if (requestQueueString !== null) {
        var requestQueue = JSON.parse(requestQueueString);
        return requestQueue;
      }
    } catch (error) {}

    return [];
  },

  async addRequestToQueue(request: Object) {
    var requestQueue = await this.getRequestQueue();
    requestQueue.push(request);
    var requestQueueString = JSON.stringify(requestQueue);

    try {
      await AsyncStorage.setItem('RequestQueue', requestQueueString);
    } catch (error) {
      // Error saving data
      console.log('error saving to Request Queue: ' + error);
    }
  },

  async removeRequestFromQueue(request: Object) {
    var requestQueue = await this.getRequestQueue();

    for (let i = 0; i < requestQueue.length; i++) {
      var aRequest = requestQueue[i];

      if (JSON.stringify(aRequest) === JSON.stringify(request)) {
        requestQueue.splice(i, 1);
        break;
      }
    }

    console.log('logging new request queue');
    console.log(requestQueue);

    var requestQueueString = JSON.stringify(requestQueue);

    try {
      await AsyncStorage.setItem('RequestQueue', requestQueueString);
    } catch (error) {
      // Error saving data
      console.log('error saving to Request Queue: ' + error);
    }
  },

  async sendRequestQueue() {
    var requestQueue = await this.getRequestQueue();

    console.log('resending outstanding requests');
    console.log(requestQueue);

    for (var i = 0; i < requestQueue.length; i++) {
      var aRequest = requestQueue[i];

      await this.sendRequest(
        aRequest.type,
        aRequest.url,
        aRequest.parameters,
        aRequest.httpMethod,
        true,
      );
    }
  },

  async sendPurchaseInfo(
    subscriptionID: number,
    contactID: string,
    transactionDate: string,
    transactionIdentifier: string,
    productIdentifier: string,
    receipt: string,
  ) {
    return new Promise(async (resolve, reject) => {
      try {
        var url = this.serverURL() + '/classapi/pppapi/sendPurchaseInfo';
        url = this.serverURL() + '/classapi/SF/pppapi/sendPurchaseInfo';

        var token = await AsyncStorage.getItem('token');

        if (token == null || token === '') {
          console.log('Error getting items: No token provided');
          reject('No token provided');
          AuthenticationController.logOut();
        }

        var deviceType = 'iOS';

        if (Platform.OS === 'android') {
          deviceType = 'Android';
        }

        var response = await this.customFetch(url, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            subscriptionID: subscriptionID,
            contactID: contactID,
            transactionDate: transactionDate,
            transactionIdentifier: transactionIdentifier,
            productIdentifier: productIdentifier,
            receipt: receipt,
            deviceType: deviceType,
            token: token,
          }),
        });

        if (response.status === 401) {
          AuthenticationController.logOut();
          reject('unauthorised');
        }

        let responseJson = await response.json();

        if (responseJson.error != null) {
          console.log('Error sending purchase info: ', responseJson.error);
          reject(responseJson.error);
        } else {
        }

        resolve(responseJson);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  },

  async createCertificate(
    fileName: string,
    quizSet: Object,
    quiz: Object,
    user: Object,
    results: Object[],
  ) {
    return new Promise(async (resolve, reject) => {
      try {
        var url = this.serverURL() + '/classapi/pppapi/createCertificate';

        var token = await AsyncStorage.getItem('token');
        var contactID = user.uid;

        if (token == null || token === '') {
          console.log('Error getting items: No token provided');
          reject('No token provided');
          AuthenticationController.logOut();
        }

        var quizSections = [];

        for (
          let questionIndex = 0;
          questionIndex < quizSet.questions.length;
          questionIndex++
        ) {
          var question = quizSet.questions[questionIndex];

          if (quizSections.includes(question.Section) === false) {
            quizSections.push(question.Section);
          }
        }

        var correctCount = 0;

        for (let resultIndex = 0; resultIndex < results.length; resultIndex++) {
          var result = results[resultIndex];

          if (result.correct) {
            correctCount++;
          }
        }

        var quizDisplayTime = moment(quiz.time).format('Do MMMM YYYY');

        var body = {
          contactID: contactID,
          token: token,
          date: quizDisplayTime,
          userName: user.name,
          quizName: quizSet.title,
          quizSections: quizSections.join(', '),
          correctCount: correctCount,
          questionCount: quiz.questions.length,
          quizType: quizSet.type,
        };

        if (Platform.OS === 'web') {
          try {
            var response = await this.customFetch(url, {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(body),
            });

            if (response.status === 401) {
              AuthenticationController.logOut();
              reject('unauthorised');
            }

            resolve(response);
          } catch (error) {
            console.log(error);
            reject(error);
          }
        } else {
          try {
            var response = await fetch(url, {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(body),
            });

            var filePath = DataController.getCertificateFilePath(fileName);

            var blob = await response.blob();
            var base64 = await this.convertBlobToBase64(blob);

            if (base64 != null) {
              var imageData = base64.split(',');
              if (imageData.length > 1) {
                imageData = imageData[1];

                await RNFS.writeFile(filePath, imageData, 'base64');

                resolve('success');
              } else {
                reject(
                  "Certificate couldn't be created. Please try again later.",
                );
              }
            } else {
              reject(
                "Certificate couldn't be created. Please try again later.",
              );
            }
          } catch (error) {
            console.log(error);
            reject("Couldn't create certificate. Please try again later.");
          }
        }
      } catch (error) {
        console.log(error);
        reject('Unable to create certificate. Please try again later.');
      }
    });
  },

  async convertBlobToBase64(blob) {
    return new Promise(async (resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
  },
};
