/**
 * PPP
 * @flow
 */
'use strict';
import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Platform,
  TouchableOpacity,
  ScrollView,
  Image,
  FlatList,
  TextInput,
} from 'react-native';

import ScreenComponent from '../components/ScreenComponent';
import QuizCell from '../components/QuizCell';
import BookCell from '../components/BookCell';
import {colors} from '../classes/Colors';
import DynamicText from '../components/DynamicText';
import FilterChip from '../components/FilterChip';
import PlatformController from '../classes/PlatformController';
import DataController from '../classes/DataController';
import {firebase} from '../classes/Firebase';

var _ = require('lodash');

type Props = {};

type State = {
  searchResultItems: [],
  searchText: string,
  selectedSearchFilter: string,
  onlyShowStartingFilter: boolean,
  recentSearchPhrases: [],
  recentSearchItems: [],
};

export default class SearchScreen extends Component {
  static navigationOptions = ({navigation}) => {
    return {
      animationEnabled: false,
      title: '',
      headerShown: false,
    };
  };

  constructor(props: Props) {
    super(props);

    if (_.isEmpty(this.props.initialState)) {
      let startingFilter = this.props.navigation.getParam(
        'startingFilter',
        null,
      );
      let onlyShowStartingFilter = false;

      if (startingFilter == null) {
        startingFilter = 'All';
      } else {
        onlyShowStartingFilter = true;
      }

      this.state = {
        searchResultItems: [],
        searchText: '',
        selectedSearchFilter: startingFilter,
        onlyShowStartingFilter: onlyShowStartingFilter,
        recentSearchPhrases: [],
        recentSearchItems: [],
      };
    } else {
      this.state = this.props.initialState;
    }
  }

  componentDidMount() {
    if (_.isEmpty(this.props.initialState)) {
      // console.log('Did mount');
      if (this.searchInput != null) {
        this.searchInput.focus();
      }
      this.updateRecentSearchPhrasesAndItems();
    }
  }

  async updateRecentSearchPhrasesAndItems() {
    let recentSearchPhrases = await DataController.getRecentSearchPhrases();
    let recentSearchItems = await DataController.getRecentSearchItems();

    this.setState({recentSearchPhrases, recentSearchItems});
  }

  async clearRecentSearchPhrases() {
    await DataController.clearRecentSearchPhrases();
    this.updateRecentSearchPhrasesAndItems();
  }

  async clearRecentSearchItems() {
    await DataController.clearRecentSearchItems();
    this.updateRecentSearchPhrasesAndItems();
  }

  searchTextDidChange(text: string) {
    this.setState({searchText: text}, () => {
      this.filterResults(text);
    });
  }

  clearSearch() {
    this.searchTextDidChange('');
  }

  doesSearchItemBelongToFilter(item) {
    if (item.book) {
      // itme is book
      if (
        this.state.selectedSearchFilter !== 'All' &&
        this.state.selectedSearchFilter !== 'Book'
      ) {
        return false;
      }
    } else {
      // item is quizSet
      if (this.state.selectedSearchFilter === 'Book') {
        return false;
      } else if (
        this.state.selectedSearchFilter === 'Quiz' &&
        item.type !== 'guideline' &&
        item.type !== 'topic' &&
        item.type !== 'section'
      ) {
        return false;
      } else if (
        this.state.selectedSearchFilter === 'Standby CPD' &&
        item.type !== 'standby'
      ) {
        return false;
      } else if (
        this.state.selectedSearchFilter === 'Case Study' &&
        item.type !== 'caseStudy'
      ) {
        return false;
      } else if (
        this.state.selectedSearchFilter === 'PGD' &&
        (item.pgd !== true || item.pma === true)
      ) {
        return false;
      } else if (
        this.state.selectedSearchFilter === 'PMA' &&
        item.pma !== true
      ) {
        return false;
      }
    }

    return true;
  }

  filterResults(text: string) {
    let lowercaseText = text.toLowerCase();

    var quizSets = this.props.screenProps.quizSets.slice();
    var books = this.props.screenProps.books.slice();
    quizSets = _.orderBy(quizSets, ['title'], ['asc']);

    var filteredMatches = [];
    var exactMatches = [];

    if (text.length > 0) {
      for (
        let quizSetIndex = 0;
        quizSetIndex < quizSets.length;
        quizSetIndex++
      ) {
        var quizSet = quizSets[quizSetIndex];

        // filter quiz sets based on search filters
        if (this.state.selectedSearchFilter === 'Book') {
          continue;
        } else if (
          this.state.selectedSearchFilter === 'Quiz' &&
          quizSet.type !== 'guideline' &&
          quizSet.type !== 'topic' &&
          quizSet.type !== 'section'
        ) {
          continue;
        } else if (
          this.state.selectedSearchFilter === 'Standby CPD' &&
          quizSet.type !== 'standby'
        ) {
          continue;
        } else if (
          this.state.selectedSearchFilter === 'Case Study' &&
          quizSet.type !== 'caseStudy'
        ) {
          continue;
        } else if (
          this.state.selectedSearchFilter === 'PGD' &&
          (quizSet.pgd !== true || quizSet.pma === true)
        ) {
          continue;
        } else if (
          this.state.selectedSearchFilter === 'PMA' &&
          quizSet.pma !== true
        ) {
          continue;
        }

        if (this.doesSearchItemBelongToFilter(quizSet) === false) {
          continue;
        }

        let score = 0;
        let titleMatch = false;

        if (
          quizSet.name.toLowerCase() === lowercaseText ||
          (quizSet.title != null &&
            quizSet.title.toLowerCase() === lowercaseText)
        ) {
          // exactMatches.push({score: 0, type: 'quizSet', item: quizSet});
          score += 100;
          titleMatch = true;
        } else if (
          quizSet.name.toLowerCase().includes(lowercaseText) ||
          (quizSet.title != null &&
            quizSet.title.toLowerCase().includes(lowercaseText))
        ) {
          // filteredMatches.push({score: 0, type: 'quizSet', item: quizSet});
          score += 1;
          titleMatch = true;
        }
        if (quizSet.guideline != null) {
          for (
            let guidelineIndex = 0;
            guidelineIndex < this.props.screenProps.guidelines.length;
            guidelineIndex++
          ) {
            let guideline = this.props.screenProps.guidelines[guidelineIndex];
            if (guideline.GLID === quizSet.guideline) {
              if (
                guideline.IndexWords != null &&
                guideline.IndexWords.includes(lowercaseText)
              ) {
                score += 1;
              }
            }
          }
        }

        if (score > 0) {
          filteredMatches.push({
            score: score,
            titleMatch: titleMatch,
            type: 'quizSet',
            item: quizSet,
          });
        }
      }

      if (this.props.screenProps.showBooksTab) {
        for (let bookIndex = 0; bookIndex < books.length; bookIndex++) {
          if (
            this.state.selectedSearchFilter !== 'All' &&
            this.state.selectedSearchFilter !== 'Book'
          ) {
            continue;
          }

          let book = books[bookIndex];

          // if searching inside a particular book - skip all other books
          let bookToSearch = this.props.navigation.getParam(
            'bookToSearch',
            null,
          );
          if (bookToSearch != null && book.ID !== bookToSearch) {
            continue;
          }

          for (
            let chapterIndex = 0;
            chapterIndex < book.chapters.length;
            chapterIndex++
          ) {
            let chapter = book.chapters[chapterIndex];

            let score = 0;
            let titleMatch = false;

            if (
              chapter.name != null &&
              chapter.name.toLowerCase() === text.toLowerCase()
            ) {
              // exactMatches.push({score: 0, type: 'book', item: {book: book, chapterName: chapter.name, chapterNumber: chapter.chapter, chapterIndex: chapterIndex, sectionIndex: 0}});
              score += 100;
              titleMatch = true;
            } else if (
              chapter.name != null &&
              chapter.name.toLowerCase().includes(text.toLowerCase())
            ) {
              // filteredMatches.push({score: 0, type: 'book', item: {book: book, chapterName: chapter.name, chapterNumber: chapter.chapter, chapterIndex: chapterIndex, sectionIndex: 0}});
              score += 1;
              titleMatch = true;
            }

            if (score > 0) {
              filteredMatches.push({
                score: score,
                titleMatch: titleMatch,
                type: 'book',
                item: {
                  book: book,
                  chapterName: chapter.name,
                  chapterNumber: chapter.chapter,
                  chapterIndex: chapterIndex,
                  sectionIndex: 0,
                },
              });
            }

            for (
              let sectionIndex = 0;
              sectionIndex < chapter.sections.length;
              sectionIndex++
            ) {
              let section = chapter.sections[sectionIndex];

              let score = 0;
              let titleMatch = false;
              let pageMatches = 0;

              if (
                section.name != null &&
                section.name.toLowerCase() === text.toLowerCase()
              ) {
                // exactMatches.push({score: 0, type: 'book', item: {book: book, chapterName: chapter.name, chapterNumber: chapter.chapter, chapterIndex: chapterIndex, sectionName: section.name, sectionIndex: sectionIndex}});
                score += 100;
                titleMatch = true;
              } else if (
                section.name != null &&
                section.name.toLowerCase().includes(text.toLowerCase())
              ) {
                // filteredMatches.push({score: 0, type: 'book', item: {book: book, chapterName: chapter.name, chapterNumber: chapter.chapter, chapterIndex: chapterIndex, sectionName: section.name, sectionIndex: sectionIndex}});
                score += 1;
                titleMatch = true;
              }

              if (section.html != null) {
                let match;
                var re = new RegExp(text, 'gi');
                var sectionHtmlLowercase = section.html.toLowerCase();
                if (text.length > 0) {
                  while ((match = re.exec(sectionHtmlLowercase)) != null) {
                    score += 1;
                    pageMatches += 1;
                  }
                }
              }

              // if (section.html != null && section.html.toLowerCase().includes(text.toLowerCase())) {
              //   score += 1;
              //   pageMatches += 1;
              // }

              if (score > 0) {
                filteredMatches.push({
                  score: score,
                  titleMatch: titleMatch,
                  pageMatches: pageMatches,
                  type: 'book',
                  item: {
                    book: book,
                    chapterName: chapter.name,
                    chapterNumber: chapter.chapter,
                    chapterIndex: chapterIndex,
                    sectionName: section.name,
                    sectionIndex: sectionIndex,
                  },
                });
              }
            }
          }
        }
      }
    }

    // filteredMatches = exactMatches.concat(filteredMatches);
    filteredMatches = _.orderBy(
      filteredMatches,
      ['titleMatch', 'score'],
      ['desc', 'desc'],
    );

    this.setState({searchResultItems: filteredMatches});

    if (this._scrollView != null && this._scrollView.scrollTo) {
      this._scrollView.scrollTo({x: 0, y: 0, animated: false});
    }
  }

  renderSearchResultItem({item}) {
    if (item.type === 'book') {
      var bookItem = item.item;

      return (
        <TouchableOpacity onPress={this.goToBookSection.bind(this, bookItem)}>
          <BookCell
            key={`${bookItem.book.ID}-${bookItem.chapterIndex}-${bookItem.sectionIndex}`}
            bookName={bookItem.book.name}
            chapterName={bookItem.chapterName}
            chapterNumber={bookItem.chapterNumber}
            sectionName={bookItem.sectionName}
            searchText={this.state.searchText}
            titleMatch={item.titleMatch}
            pageMatches={item.pageMatches}
            fontSize={this.props.screenProps.fontSize}
          />
        </TouchableOpacity>
      );
    } else {
      // item is a quiz set
      var quizSet = item.item;

      var results =
        this.props.screenProps.latestResultsForQuizSets[quizSet.name];

      var inProgress = false;
      if (
        this.props.screenProps.inProgressQuizzes.find(
          aQuizSet => aQuizSet.quizSet === quizSet.name,
        )
      ) {
        inProgress = true;
      }

      return (
        <TouchableOpacity onPress={this.goToQuizOverview.bind(this, quizSet)}>
          <QuizCell
            key={quizSet.name}
            quizSet={quizSet}
            results={results}
            searchText={this.state.searchText}
            inProgress={inProgress}
            fontSize={this.props.screenProps.fontSize}
            user={this.props.screenProps.user}
          />
        </TouchableOpacity>
      );
    }
  }

  async goToQuizOverview(quizSet: Object) {
    await DataController.saveRecentSearchItem(quizSet);

    if (this.state.searchText.length > 2) {
      await DataController.saveRecentSearchPhrase(this.state.searchText);
    }

    this.updateRecentSearchPhrasesAndItems();

    if (quizSet.type === 'standby') {
      var selectedIssue = null;

      for (
        var issueIndex = 0;
        issueIndex < this.props.screenProps.issues.length;
        issueIndex++
      ) {
        var issue = this.props.screenProps.issues[issueIndex];

        if (issue.Name === quizSet.issueName) {
          selectedIssue = issue;
        }
      }

      this.props.navigation.navigate(
        'StandbyVolume',
        {
          quizSet: quizSet,
          issue: selectedIssue,
        },
        this.state,
      );
    } else if (quizSet.type === 'caseStudy') {
      var selectedCaseStudy = {};

      for (
        var caseStudyIndex = 0;
        caseStudyIndex < this.props.screenProps.caseStudies.length;
        caseStudyIndex++
      ) {
        var caseStudy = this.props.screenProps.caseStudies[caseStudyIndex];

        if (caseStudy.Name === quizSet.caseStudy) {
          selectedCaseStudy = caseStudy;
        }
      }

      this.props.navigation.navigate(
        'CaseStudyOverview',
        {
          quizSet: quizSet,
          caseStudy: selectedCaseStudy,
        },
        this.state,
      );
    } else {
      this.props.navigation.navigate(
        'QuizOverview',
        {
          quizSet: quizSet,
          from: 'Search',
        },
        this.state,
      );
    }

    try {
      firebase
        .analytics()
        .logEvent('search', {search_term: this.state.searchText});
    } catch (error) {
      console.log(error);
    }
  }

  async goToBookSection(item) {
    if (
      item.book != null &&
      item.chapterIndex != null &&
      item.sectionIndex != null
    ) {
      if (this.state.searchText.length > 2) {
        await DataController.saveRecentSearchPhrase(this.state.searchText);
      }

      await DataController.saveRecentSearchItem(item);

      this.updateRecentSearchPhrasesAndItems();

      this.props.navigation.navigate(
        'BookReading',
        {
          book: item.book,
          chapterIndex: item.chapterIndex,
          sectionIndex: item.sectionIndex,
          searchTerm: this.state.searchText,
        },
        this.state,
      );
    }

    try {
      firebase
        .analytics()
        .logEvent('search', {search_term: this.state.searchText});
    } catch (error) {
      console.log(error);
    }
  }

  searchFilterTapped(name: string) {
    var that = this;

    this.setState({selectedSearchFilter: name}, () => {
      that.filterResults(that.state.searchText);
    });
  }

  keyExtractor(item, index) {
    return item.item.name;
  }

  render() {
    var searchTextInputHeight = 32;

    if (Platform.OS === 'android') {
      searchTextInputHeight = 42;
    }

    var clearSearchLayout = null;

    if (this.state.searchText.length > 0) {
      clearSearchLayout = (
        <View>
          <TouchableOpacity onPress={this.clearSearch.bind(this)}>
            <Image
              style={{marginRight: 10}}
              source={require('../assets/SearchBar_Close.png')}
            />
          </TouchableOpacity>
        </View>
      );
    }

    let recentSearchItemsLayout = [];

    for (
      let recentSearchItemIndex = 0;
      recentSearchItemIndex < this.state.recentSearchItems.length;
      recentSearchItemIndex++
    ) {
      let recentSearchItem =
        this.state.recentSearchItems[recentSearchItemIndex];

      if (this.doesSearchItemBelongToFilter(recentSearchItem)) {
        let recentSearchItemType = 'quizSet';
        if (recentSearchItem.book != null) {
          recentSearchItemType = 'book';
        }
        recentSearchItemsLayout.push(
          this.renderSearchResultItem({
            item: {type: recentSearchItemType, item: recentSearchItem},
          }),
        );
      }
    }

    return (
      <ScreenComponent style={[styles.container]}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: colors.NavBar,
            paddingTop: PlatformController.getTopInset() + 24,
          }}>
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
              alignItems: 'center',
              backgroundColor: colors.SearchBar,
              borderRadius: 5,
              marginLeft: 10,
              marginTop: 6,
              marginBottom: 6,
              paddingVertical: 4,
            }}>
            <Image
              style={{marginLeft: 10}}
              source={require('../assets/SearchBar_Search.png')}
            />

            <TextInput
              ref={ref => (this.searchInput = ref)}
              style={{
                flex: 1,
                fontSize: 16,
                marginHorizontal: 8,
                height: searchTextInputHeight,
                color: colors.white,
              }}
              underlineColorAndroid="transparent"
              onChangeText={text => this.searchTextDidChange(text)}
              value={this.state.searchText}
              placeholder={'Search'}
              placeholderTextColor={'#BBB'}
              autoCapitalize={'none'}
              autoCorrect={false}
              returnKeyType={'search'}
            />
            {clearSearchLayout}
          </View>
          <TouchableOpacity onPress={() => this.props.navigation.goBack()}>
            <Text
              style={{
                color: colors.white,
                fontSize: 16,
                paddingHorizontal: 16,
              }}>
              Cancel
            </Text>
          </TouchableOpacity>
        </View>

        {this.state.searchText.length > 0 ? (
          <ScrollView
            style={{flexGrow: 0}}
            horizontal={true}
            contentContainerStyle={{
              paddingHorizontal: 10,
              paddingVertical: 10,
            }}>
            {this.state.onlyShowStartingFilter === false ||
            this.state.selectedSearchFilter === 'All' ? (
              <FilterChip
                name="All"
                selected={this.state.selectedSearchFilter === 'All'}
                onPress={this.searchFilterTapped.bind(this)}
              />
            ) : null}
            {this.state.onlyShowStartingFilter === false ||
            this.state.selectedSearchFilter === 'Quiz' ? (
              <FilterChip
                name="Quiz"
                selected={this.state.selectedSearchFilter === 'Quiz'}
                onPress={this.searchFilterTapped.bind(this)}
              />
            ) : null}
            {this.state.onlyShowStartingFilter === false ||
            this.state.selectedSearchFilter === 'Standby CPD' ? (
              <FilterChip
                name="Standby CPD"
                selected={this.state.selectedSearchFilter === 'Standby CPD'}
                onPress={this.searchFilterTapped.bind(this)}
              />
            ) : null}
            {this.state.onlyShowStartingFilter === false ||
            this.state.selectedSearchFilter === 'Case Study' ? (
              <FilterChip
                name="Case Study"
                selected={this.state.selectedSearchFilter === 'Case Study'}
                onPress={this.searchFilterTapped.bind(this)}
              />
            ) : null}
            {this.state.onlyShowStartingFilter === false ||
            this.state.selectedSearchFilter === 'PGD' ? (
              <FilterChip
                name="PGD"
                selected={this.state.selectedSearchFilter === 'PGD'}
                onPress={this.searchFilterTapped.bind(this)}
              />
            ) : null}
            {this.state.onlyShowStartingFilter === false ||
            this.state.selectedSearchFilter === 'PMA' ? (
              <FilterChip
                name="PMA"
                selected={this.state.selectedSearchFilter === 'PMA'}
                onPress={this.searchFilterTapped.bind(this)}
              />
            ) : null}
            {this.props.screenProps.showBooksTab &&
            (this.state.onlyShowStartingFilter === false ||
              this.state.selectedSearchFilter === 'Book') ? (
              <FilterChip
                name="Book"
                selected={this.state.selectedSearchFilter === 'Book'}
                onPress={this.searchFilterTapped.bind(this)}
              />
            ) : null}
          </ScrollView>
        ) : null}

        {this.state.searchText.length > 0 ? (
          <DynamicText
            style={{
              fontSize: 13,
              fontWeight: '600',
              color: colors.Grey900,
              marginHorizontal: 10,
              marginBottom: 16,
            }}>
            {this.state.searchResultItems.length} result
            {this.state.searchResultItems.length !== 1 ? 's' : ''} for '
            {this.state.searchText}'
          </DynamicText>
        ) : null}

        {this.state.searchText.length > 0 ? (
          <View
            style={{
              flex: 1,
              shadowOffset: {width: 0, height: 1},
              shadowRadius: 1,
              shadowColor: 'black',
              shadowOpacity: 0.2,
              backgroundColor: colors.white,
            }}>
            <FlatList
              style={{flex: 1, zIndex: 0}}
              data={this.state.searchResultItems}
              extraData={[
                this.props.screenProps.latestResultsForQuizSets,
                this.props.screenProps.fontSize,
              ]}
              keyExtractor={this.keyExtractor.bind(this)}
              renderItem={this.renderSearchResultItem.bind(this)}
            />
          </View>
        ) : (
          <ScrollView>
            {this.state.recentSearchPhrases.length > 0 ? (
              <View>
                <Text
                  style={{
                    fontSize: 13,
                    fontWeight: '600',
                    color: colors.Grey900,
                    marginHorizontal: 10,
                    marginTop: 16,
                    marginBottom: 10,
                  }}>
                  Recent searches
                </Text>

                <View style={{backgroundColor: colors.white}}>
                  {this.state.recentSearchPhrases.map((item, index) => {
                    return (
                      <TouchableOpacity
                        key={index}
                        activeOpacity={0.8}
                        style={{
                          borderBottomWidth: 1,
                          borderColor: colors.Grey100,
                        }}
                        onPress={() => this.searchTextDidChange(item)}>
                        <Text
                          style={{
                            fontSize: 17,
                            fontWeight: '600',
                            color: 'black',
                            marginHorizontal: 10,
                            marginVertical: 10,
                          }}>
                          {item}
                        </Text>
                      </TouchableOpacity>
                    );
                  })}
                </View>

                <View style={{alignItems: 'flex-end'}}>
                  <TouchableOpacity
                    onPress={this.clearRecentSearchPhrases.bind(this)}>
                    <Text
                      style={{
                        fontWeight: '600',
                        color: colors.ButtonBlue,
                        paddingHorizontal: 16,
                        paddingTop: 8,
                        paddingBottom: 2,
                      }}>
                      Clear
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            ) : null}

            {recentSearchItemsLayout.length > 0 ? (
              <View>
                <Text
                  style={{
                    fontSize: 13,
                    fontWeight: '600',
                    color: colors.Grey900,
                    marginHorizontal: 10,
                    marginVertical: 10,
                  }}>
                  Recently viewed
                </Text>

                <View style={{backgroundColor: colors.white}}>
                  {recentSearchItemsLayout}
                </View>

                <View style={{alignItems: 'flex-end'}}>
                  <TouchableOpacity
                    onPress={this.clearRecentSearchItems.bind(this)}>
                    <Text
                      style={{
                        fontWeight: '600',
                        color: colors.ButtonBlue,
                        paddingHorizontal: 16,
                        paddingTop: 8,
                        paddingBottom: 20,
                      }}>
                      Clear
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            ) : null}
          </ScrollView>
        )}
      </ScreenComponent>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.Grey100,
  },
});
