/**
 * PPP App
 * @flow
 */
'use strict';

import {Platform} from 'react-native';

export default {
  getAppVersion() {
    return 'Web 2.4.7';
  },

  getModel() {
    return '';
  },

  getBrand() {
    return '';
  },

  getSystemVersion() {
    return '';
  },
};
